import React from 'react';

import Title from '../title';
import Button from '../button';
import Animate from '../animate';

import './index.scss';

import e1614098322734 from './media/image001-e1614098322734.jpg';
import jon from './media/Jon-Favreau-Crooked-Media.png';
import phoebe from './media/phoebe-bridgers-getty-grid-2.jpg';
import spotifyVideo from './media/Spotify-Video-Podcasts.jpg';
import e23344323423 from './media/SongStart-Podcast-Cover-Art-1-1-768x768.jpeg';
import e243234242 from './media/hayley-williams-3.png';
import e243234242123123 from './media/spotify-Tamar-Kaprelian-Ali-Tamposi-billboard-1548-1634827558-compressed.jpg';
import e32rygwrfgqf from './media/e32rygwrfgqf.png';
import EE73A408787806E32163F448D56A68E from './media/5EE73A408787806E32163F448D56A68E.jpg';

const data = [
    {
        source: 'FoxLA',
        image: EE73A408787806E32163F448D56A68E,
        title: `‘Song Start’: A new digital education video and podcast series`,
        url: 'https://www.foxla.com/news/song-start-a-new-digital-education-video-and-podcast-series',
    },
    {
        source: 'Billboard',
        image: e243234242123123,
        title: `Spotify Partners With Ali Tamposi and Tamar Kaprelian to Launch Song Start`,
        url: 'https://www.billboard.com/articles/business/9648485/spotify-song-start-education-platform-ali-tamposi-tamar-kaprelian/',
    },
    {
        source: 'chorus.fm',
        image: e243234242,
        title: `Hayley Williams on Song Start Podcast`,
        url: 'https://chorus.fm/news/hayley-williams-on-song-start-podcast/',
    },
    {
        source: 'AllAccess',
        image: e32rygwrfgqf,
        title: `Spotify Partners With Song Start Music Education Video & Podcast Initiative`,
        url: 'https://www.allaccess.com/net-news/archive/story/212448/spotify-partners-with-song-start-music-education-v',
    },
    {
        source: 'Hypebot',
        image: e23344323423,
        title: `Spotify For Artists, Song Start launch free music education series with John Legend, Phoebe Bridgers, Charli XCX, more`,
        url: 'https://www.hypebot.com/hypebot/2021/10/spotify-for-artists-song-start-launch-free-music-education-series-with-john-legend-pheobe-bridgers-charli-xcx-more.html',
    },
    {
        source: 'Musically',
        image: spotifyVideo,
        title: `Spotify opens video podcasts up to many more Anchor creators`,
        url: 'https://musically.com/2021/10/22/spotify-opens-video-podcasts-anchor/',
    },
    {
        source: 'Uproxx',
        image: phoebe,
        title: `Phoebe Bridgers Thinks Being Annoying Helped Her Learn How To Sing`,
        url: 'https://uproxx.com/indie/phoebe-bridgers-annoying-learn-sing',
    },
    {
        source: 'Variety',
        image: e1614098322734,
        title: `Songwriters Ali Tamposi and Tamar Kaprelian Launch ‘Song Start,’ Education Series With Phoebe Bridgers, John Legend, More`,
        url: 'https://variety.com/2021/music/news/ali-tamposi-tamar-kaprelian-song-start-john-legend-camila-cabello-1235094850/',
    },
    {
        source: 'Variety',
        image: jon,
        title: `Jon Favreau Podcast About Solving ‘Our Hellish Online Existence’ Gets Premiere Date`,
        url: 'https://variety.com/2021/digital/news/offline-jon-favreau-podcast-crooked-media-roundup-1235094494/',
    },
    {
        source: 'Variety',
        image: e1614098322734,
        title: `Songwriters Ali Tamposi and Tamar Kaprelian to Launch Music Education Series With Spotify (EXCLUSIVE)`,
        url: 'https://variety.com/2021/music/news/ali-tamposi-tamar-kaprelian-spotify-song-start-music-education-series-1234909758/',
    },
];

const News = () => {
    return (
        <div className="guide">
            <div className="sub-page-holder">
                <Title name="Latest News"/>
                <div className="latest-news-holder">
                    {data.map((item, index) => (
                        <ul
                            key={index}
                            className="latest-news"
                        >
                            <li>
                                <Animate>
                                    <figure
                                        style={{
                                            backgroundImage: `url(${item.image})`,
                                        }}
                                    />
                                </Animate>
                            </li>
                            <li>
                                <div className="latest-news-details-holder">
                                    <Animate delay={100}>
                                        <div className="border-animate border-white latest-news-details">
                                            <Animate delay={200}>
                                                <h3>
                                                    {item.title}
                                                </h3>
                                            </Animate>
                                            <Animate delay={300}>
                                                <Button
                                                    href={item.url}
                                                    target="_blank"
                                                    color="light"
                                                >
                                                    {`Read on ${item.source}`}
                                                </Button>
                                            </Animate>
                                        </div>
                                    </Animate>
                                </div>
                            </li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default News;
