import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InView } from 'react-intersection-observer';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const Animate = props => {
    const {
        delay,
        animate,
        children,
        infinite,
        animation,
        threshold,
        className,
        forceAnimate,
    } = props;

    const { isMobile } = useDevice();

    return animate ? (
        <InView
            triggerOnce={!infinite}
            threshold={isMobile ? 0 : threshold}
        >
            {({ inView, ref }) => (
                <div
                    ref={ref}
                    className={classnames(
                        className,
                        `animated-holder`,
                        `relative`,
                        `a-${animation}`,
                        {
                            'animate': forceAnimate || inView,
                        }
                    )}
                    style={{
                        animationDelay: `${delay}ms`,
                    }}
                >
                    {children}
                </div>
            )}
        </InView>
    ) : (
        children
    );
};

Animate.defaultProps = {
    delay: 0,
    animate: true,
    animation: 'fadeInUp',
    infinite: false,
    forceAnimate: false,
    threshold: 0.2,
};

Animate.propTypes = {
    delay: PropTypes.number,
    animate: PropTypes.bool,
    children: PropTypes.node,
    infinite: PropTypes.bool,
    animation: PropTypes.oneOf(['fadeInUp', 'fadeIn']),
    className: PropTypes.string,
    threshold: PropTypes.number,
    forceAnimate: PropTypes.bool
};

export default Animate;
