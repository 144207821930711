import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { titleConfig } from '../../constants/props';

import Animate from '../animate';

import './index.scss';

const Title = props => {
    const {
        name,
        line,
        size,
        color,
        reflection,
    } = props;

    return (
        <div
            className={classNames(
                `title-holder`,
                `s-${size}`,
                `c-${color}`,
            )}
        >
            <h2>
                <Animate>
                    <span>{name}</span>
                </Animate>
                {!!reflection && (
                    <Animate delay={100}>
                        <span className="last">{name}</span>
                    </Animate>
                )}
            </h2>
            {!!line && (
                <Animate delay={200}>
                    <div className="title-line"/>
                </Animate>
            )}
        </div>
    );
};

Title.defaultProps = {
    line: true,
    size: titleConfig.size[0],
    color: titleConfig.color[0],
    reflection: true,
};

Title.propTypes = {
    name: PropTypes.string,
    line: PropTypes.bool,
    size: PropTypes.oneOf(titleConfig.size),
    color: PropTypes.oneOf(titleConfig.color),
    reflection: PropTypes.bool,
};

export default Title;
