import React from 'react';

import Animate from '../animate';

import './index.scss';

const Footer = () => {
    return (
        <footer>
            <div className="guide">
                <Animate>
                    <div className="footer-content">
                        {`Song Start © ${new Date().getFullYear()}`}
                    </div>
                </Animate>
            </div>
        </footer>
    );
};

export default Footer;
