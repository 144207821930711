import React from 'react';
import Animate from '../animate';

import Title from '../title';

import './index.scss';

const data = [
    'Songwriters',
    'Producers',
    'Artists',
    'Aspiring Creators',
    'Music Lovers',
];

const CreatedBy = () => {
    return (
        <div className="guide">
            <ul className="created-by-holder">
                <li>
                    <Title name="Created for and by"/>
                </li>
                <li>
                    <div className="created-by">
                        {data.map((item, index) => (
                            <Animate
                                key={index}
                                delay={100 + (index * 50)}
                            >
                                <h3>
                                    {item}
                                </h3>
                            </Animate>
                        ))}
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default CreatedBy;
