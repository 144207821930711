import React, { useState } from 'react';
import classNames from 'classnames';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Loading = () => {
    const {
        setLoading,
    } = useSharedContext();

    const [animationEnded, setAnimationEnded] = useState(false);

    return (
        <ul
            className={classNames(
                `loading-holder`,
                {
                    'active': !animationEnded,
                }
            )}
            onTransitionEnd={() => setLoading(false)}
        >
            <li>
                <div
                    className="icon-logo-song"
                    onAnimationEnd={() => setAnimationEnded(true)}
                />
            </li>
            <li>
                <div className="icon-logo-start"/>
            </li>
        </ul>
    );
};

export default Loading;
