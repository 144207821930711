import React from 'react';

import Animate from '../animate';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const Intro = () => {
    const { isMobile } = useDevice();

    return (
        <div className="guide">
            <div className="intro-holder">
                <Animate delay={isMobile ? 100 : 500}>
                    <div className="intro"/>
                </Animate>
                <div className="intro-statement-holder">
                    <Animate delay={isMobile ? 400: 550}>
                        <h1 className="intro-statement border-animate">
                            <strong>Song Start is your free online digital music education platform</strong>
                            {` `}
                            <span>designed to give everyone access to the necessary tools to thrive in the modern day music industry.</span>
                        </h1>
                    </Animate>
                </div>
            </div>
        </div>
    );
};

export default Intro;
