import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './containers/layout';

import './assets/scss/index.scss';

function App() {
    return (
        <BrowserRouter>
            <Layout/>
        </BrowserRouter>
    );
}

export default App;
