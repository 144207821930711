import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { conditionalSpread } from '../../helpers';

import { toConfig } from '../../constants/props';

const To = props => {
    const {
        url,
        href,
        target,
        onClick,
        children,
        className,
    } = props;

    return href ? (
        <a
            href={href}
            onClick={onClick}
            target={target}
            {...conditionalSpread({rel: 'noopener noreferrer'}, target !== '_self')}
            className={className}
        >
            {children}
        </a>
    ) : (
        <NavLink
            exact
            to={url}
            onClick={onClick}
            target={target}
            className={className}
        >
            {children}
        </NavLink>
    )
};

To.defaultProps = {
    url: '',
    target: toConfig.target[0],
};

To.propTypes = {
    url: PropTypes.any,
    href: PropTypes.any,
    target: PropTypes.oneOf(toConfig.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
};

export default To;
