import React from 'react';

import Animate from '../../components/animate';

import './index.scss';

const ComingSoon = props => {
    const {
        children,
    } = props;

    return (
        <div className="coming-soon-holder">
            <div className="guide">
                <Animate>
                    <h2>{children}</h2>
                </Animate>
            </div>
        </div>
    );
};

export default ComingSoon;
