import React from 'react';

import To from '../to';
import Animate from '../animate';

import useDevice from '../../hooks/useDevice';

import { socials } from '../../constants/config';

import './index.scss';

const Socials = () => {
    const { isMobile } = useDevice();

    return (
        <ul className="socials-holder">
            <li>
                <Animate
                    delay={50}
                    animate={!isMobile}
                >
                    <div className="socials-line"/>
                </Animate>
            </li>
            <li>
                {Object.values(socials).map((item, index) => (
                    <Animate
                        key={index}
                        delay={index * 50}
                        animate={!isMobile}
                    >
                        <To
                            href={item.url}
                            target="_blank"
                        >
                            <span className={item.icon}/>
                        </To>
                    </Animate>
                ))}
            </li>
            <li>
                <Animate
                    delay={300}
                    animate={!isMobile}
                >
                    <div className="socials-line"/>
                </Animate>
            </li>
        </ul>
    );
};

export default Socials;
