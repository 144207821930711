import React from 'react';
import classNames from 'classnames';

import Title from '../title';
import Animate from '../animate';

import useDevice from '../../hooks/useDevice';

import ali from './media/ali.jpg';
import eric from './media/eric.jpg';
import carly from './media/carly.jpg';
import tamar from './media/tamar.jpg';
import milla from './media/milla.jpg';
import olivia from './media/olivia.jpg';

import './index.scss';

const data = [
    {
        name: 'Tamar Kaprelian',
        headline: 'Co-Founder',
        image: tamar,
        children: `Tamar Kaprelian is an Armenian-American artist, activist, and music industry veteran. A Phi-Beta-Kappa and Summa Cum Laude graduate of Columbia University, she signed major recording contracts with RCA & Interscope Records and honed her craft with some of LA’s top songsmiths. Tamar founded Nvak Foundation in 2016 with the intention of bringing quality music education to under-served communities globally. In 2021, she launched an artist centric talent incubator and record label called Nvak Collective. Now, she’s partnered with Spotify and songwriter, Ali Tamposi, to launch Song Start, this innovative e-learning platform aimed to make music education easily accessible to millions of creatives globally.`,
    },
    {
        name: 'Ali Tamposi',
        headline: 'Co-Founder',
        image: ali,
        children: `As a songwriter, Ali Tamposi relies on a dynamic personal history and a keen sense of empathy to transpose real memories and moments into anthems for some of the most influential artists of all-time. Kickstarting her career in 2008, she has co-written for a wide variety of artists including Beyoncé, Cardi B, Dua Lipa, Selena Gomez, blink-182, James Blake, Justin Bieber, Ozzy Osbourne, BTS, Kelly Clarkson (for whom she penned “(Stronger)” and earned her first GRAMMY®️ Award nod), and most recently Miley Cyrus for her hit “Midnight Sky” and new album Plastic Hearts. With billions of streams, three number one entries on the Billboard Hot 100 out of ten in the Top 10, and dozens of multi-platinum certifications, she garnered BMI’s “Pop Songwriter of The Year” award alongside frequent collaborator Andrew Watt in 2019, and received the “Song of the Year” award at the 2013 BMI London Awards.`,
    },
    {
        name: 'Eric Leva',
        headline: 'Songwriter',
        image: eric,
        children: `Eric Leva is a songwriter from Massachusetts. At the age of 8, he was accepted to the prestigious New England Conservatory of Music to study piano. During the next decade of rigorous classical training, a handful of mainstream and indie records caught his ear. To cope with the pressure of the conservatory, Leva escaped by writing lyrics. Later, while studying at Berklee College of Music, the focus shifted to developing his songwriting craft. Postgrad, Leva won an award from the ASCAP Foundation, opening up opportunities to collaborate with artists and producers in the music industry (including releases by Kesha, Celeste, Pentatonix, AJ Mitchell, DNCE, Slander, Marshmello, etc). He continues to collaborate on a daily basis with some of the industry’s most celebrated creatives, constantly paying it forward through involvement in various music education initiatives around the world.`,
    },
    {
        name: 'Carly Sharp',
        headline: 'Photo & Video Creation',
        image: carly,
        children: `Carly Sharp is a photographer and director based in Los Angeles specializing in music artists and short-form content. She is a recent graduate of UCLA where she studied film, dance, and communications. In addition to working with Nvak Collective, Carly collaborated with the Song Start team to create videos that supplement the main curriculum and captured BTS that allow for an inside look at the making of the series.`,
    },
    {
        name: 'Olivia Reid',
        headline: 'Season 1 Podcast Host',
        image: olivia,
        children: `As a performing artist, songwriter, producer, sound engineer, and podcast host, Olivia has her fingerprint on every facet of the music industry. Olivia is a recent Summa Cum Laude graduate of NYU’s Clive Davis Institute of Recorded Music, and creates genre-melding alternative music that blends organic, acoustic sounds with electronic pop. She first entered the music industry scene as a songwriter back in 2016, and since then has garnered over 30 Million streams across her writing catalog, including her song “Above the Clouds” with SŸDE.  In addition to assisting in writing Song Start’s curriculum, Olivia is Song Start’s Season 1’s Podcast Host.`,
    },
    {
        name: 'Milla Meissner',
        headline: 'Intern',
        image: milla,
        children: `Milla Meissner is a Berlin-based singer, songwriter and multi-instrumentalist currently based in Berlin, studying Professional Musicianship (BA Songwriting) at the British Irish Modern Music Institute. As an emerging artist she is passionate about connecting with musicians from all around the world, focusing on songwriting and collaboration. She recently released her debut singles under the artist name “MILLA”, blending catchy pop melodies with mellow indie sounds. After her graduation she is looking forward to starting her journey within the music industry.`,
    },
];

const Team = () => {
    const { isMobile } = useDevice();

    return (
        <div className="guide">
            <div className="sub-page-holder gap-big">
                <Title name="The Team of Your Dreams"/>
                {data.map((member, index) => (
                    <ul
                        key={index}
                        className={classNames(
                            `team-member`,
                            {
                                'switch': index % 2,
                            }
                        )}
                    >
                        <li>
                            <Animate>
                                <figure
                                    style={{
                                        backgroundImage: `url(${member.image})`,
                                    }}
                                />
                            </Animate>
                        </li>
                        <li>
                            <div className="tm-children-holder">
                                <Animate delay={isMobile ? 0 : 200}>
                                    <div className="border-animate tm-children">
                                        <Animate delay={300}>
                                            <div className="tm-name">
                                                {member.name}
                                            </div>
                                        </Animate>
                                        <Animate delay={400}>
                                            <div className="tm-headline">
                                                {member.headline}
                                            </div>
                                        </Animate>
                                        <Animate delay={500}>
                                            <p>
                                                {member.children}
                                            </p>
                                        </Animate>
                                    </div>
                                </Animate>
                            </div>
                        </li>
                    </ul>
                ))}
            </div>
        </div>
    );
};

export default Team;
