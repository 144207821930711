const uiKitSizes = [
    'small',
    'default',
    'medium',
    'big',
    'extra-big',
    'extra-large',
];

const uiKitCornerRadius = [
    'full',
    'smooth',
];

export const toConfig = {
    target: [
        '_self',
        '_blank',
        '_parent',
        '_top',
    ],
};

export const buttonConfig = {
    color: [
        'dark',
        'light',
    ],
};

export const titleConfig = {
    size: [
        'default',
        'extra',
    ],
    color: [
        'gold',
        'dark',
    ],
}

export const inputConfig = {
    type: [
        'text',
        'tel',
        'date',
        'time',
        'email',
        'number',
        'search',
        'price',
        'select',
        'password',
    ],
    size: uiKitSizes,
    cornerRadius: uiKitCornerRadius,
};

export const checkboxConfig = {
    align: [
        'default',
        'reverse',
    ],
};

export const informationMessageConfig = {
    type: [
        'error',
        'info',
    ],
    appearance: [
        'box',
        'inline',
        'outline',
        'light',
    ],
};
