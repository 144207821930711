import React from 'react';

import Title from '../title';
import Button from '../button';
import Animate from '../animate';

import { socials } from '../../constants/config';

import './index.scss';

const YouTube = () => {
    return (
        <div className="youtube-section-holder">
            <div className="youtube-pattern">
                {Array(20).fill(Array(4).fill('')).map((arr, index) => (
                    <ul key={index}>
                        {arr.map((_, index) => (
                            <li key={index}>
                                <p>Vocal</p>
                                <p>Songwriting</p>
                                <p>Producing</p>
                            </li>
                        ))}
                    </ul>
                ))}
            </div>
            <div className="guide">
                <ul className="youtube-content">
                    <li>
                        <Title
                            color="dark"
                            line={false}
                            name="Exclusive Content"
                        />
                    </li>
                    <li>
                        <Animate delay={200}>
                            <Button
                                target="_blank"
                                color="light"
                                href={socials.youTube.url}
                            >
                                See on YouTube
                            </Button>
                        </Animate>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default YouTube;
