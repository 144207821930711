export const socials = {
    faceBook: {
        url: 'https://www.facebook.com/songstart',
        icon: 'icon-facebook',
    },
    twitter: {
        url: 'https://www.twitter.com/song__start',
        icon: 'icon-twitter',
    },
    instagram: {
        url: 'https://www.instagram.com/songstart',
        icon: 'icon-instagram',
    },
    tikTok: {
        url: 'https://www.tiktok.com/@song.start',
        icon: 'icon-tikTok',
    },
    youTube: {
        url: 'https://www.youtube.com/channel/UC8R8g7wnRilOY2YjJavRhCw',
        icon: 'icon-youTube',
    },
    linkedIn: {
        url: 'https://www.linkedin.com/company/song-start/',
        icon: 'icon-linkedin',
    },
};

export const enteredCookie = 'enteredWebsite';
