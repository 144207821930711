import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import To from '../to';

import { buttonConfig, toConfig } from '../../constants/props';

import './index.scss';

const Button = props => {
    const {
        to,
        href,
        type,
        color,
        target,
        onClick,
        children,
    } = props;

    const sharedProps = {
        onClick,
        className: classNames(
            `btn`,
            `c-${color}`,
        ),
    };

    return (to || href) ? (
        <To
            to={to}
            href={href}
            target={target}
            {...sharedProps}
        >
            {children}
        </To>
    ) : (
        <button
            type={type}
            {...sharedProps}
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    color: buttonConfig.color[0],
};

Button.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.oneOf(buttonConfig.color),
    target: PropTypes.oneOf(toConfig.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
};

export default Button;
