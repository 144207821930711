import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Observer = props => {
    const {
        slug,
        children,
    } = props;

    const {
        setActiveSection,
    } = useSharedContext();

    const { ref, inView } = useInView({ threshold: 0.5 });

    useEffect(() => {
        inView && setActiveSection(slug);
    }, [inView, slug]); // eslint-disable-line

    return (
        <div
            ref={ref}
            data-slug={slug}
            className='observer-holder'
        >
            {children}
        </div>
    );
};

Observer.propTypes = {
    slug: PropTypes.string,
    children: PropTypes.any,
};

export default Observer;
