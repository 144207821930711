import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import To from '../to';
import Animate from '../animate';
import Socials from '../socials';

import useDevice from '../../hooks/useDevice';

import { scrollTo } from '../../helpers';

import './index.scss';

const data = [
    {
        name: 'About',
        slug: 'about',
    },
    {
        name: 'Interviews',
        url: '/interviews',
    },
    {
        name: 'Courses',
        url: '/courses',
    },
    {
        name: 'Events',
        url: '/events',
    },
    {
        name: 'Team',
        slug: 'team',
    },
    {
        name: 'News',
        slug: 'news',
    },
    {
        name: 'Merch',
        url: '/merch',
    },
];

const navItemClassName = 'nav-item';

const Header = () => {
    const location = useLocation();
    const { isMobile } = useDevice();
    const [navigationOpened, setNavigationOpened] = useState(false);

    useEffect(() => {
        const scrollWatcher = () => {
            document.documentElement.classList[window.scrollY > 100 ? 'add' : 'remove']('small-header');
        };
        scrollWatcher();

        window.addEventListener('scroll', scrollWatcher);

        return () => window.removeEventListener('scroll', scrollWatcher);
    }, []);

    useEffect(() => {
        const className = 'header-mounted';
        document.documentElement.classList.add(className);

        return () => document.documentElement.classList.remove(className);
    }, []);

    const navigation = (
        <>
            <li>
                <Socials/>
            </li>
            <li>
                <nav>
                    <ul>
                        {data.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => setNavigationOpened(false)}
                            >
                                <Animate delay={300 + (index * 50)} animate={!isMobile}>
                                    {item.url ? (
                                        <To
                                            url={item.url}
                                            className={navItemClassName}
                                        >
                                            {item.name}
                                        </To>
                                    ) : (
                                        location.pathname === '/' ? (
                                            <div
                                                className={navItemClassName}
                                                onClick={() => scrollTo(item.slug)}
                                            >
                                                {item.name}
                                            </div>
                                        ) : (
                                            <To
                                                url={`/#${item.slug}`}
                                                className={navItemClassName}
                                            >
                                                {item.name}
                                            </To>
                                        )
                                    )}
                                </Animate>
                            </li>
                        ))}
                    </ul>
                </nav>
            </li>
        </>
    );


    return (
        <>
            <header
                className={classNames(
                    {
                        'black': navigationOpened,
                    }
                )}
            >
                <div className="guide">
                    <ul className="header-content">
                        <li>
                            <Animate>
                                <To
                                    url="/"
                                    className="logo-holder"
                                    onClick={() => {
                                        setNavigationOpened(false);
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <span className="icon-icon-logo-text"/>
                                </To>
                            </Animate>
                        </li>
                        {isMobile ? (
                            <li>
                                <Animate delay={50}>
                                    <button
                                        className={classNames(
                                            `mobile-navigation-toggle`,
                                            {
                                                'active': navigationOpened,
                                            }
                                        )}
                                        onClick={() => setNavigationOpened(val => !val)}
                                    >
                                        <i/>
                                        <i/>
                                    </button>
                                </Animate>
                            </li>
                        ) : navigation}
                    </ul>
                </div>
            </header>
            {isMobile && (
                <div
                    className={classNames(
                        `mobile-navigation-holder`,
                        {
                            'active': navigationOpened,
                        }
                    )}
                >
                    <ul>
                        {navigation}
                    </ul>
                </div>
            )}
        </>
    );
};

export default Header;
