import React from 'react';

import Title from '../../components/title';
import Button from '../../components/button';
import Animate from '../../components/animate';
import To from '../../components/to';

import { interviews } from '../../content/interviews';

import './index.scss';

const Interviews = () => {
    return (
        <div className="guide">
            <Title
                name='Interviews'
            />
            <div className="interviews-holder">
                {interviews.map((item, index) => (
                    <ul
                        key={index}
                        className="interview-item"
                    >
                        <li>
                            <Animate>
                                <figure>
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                    />
                                    <To
                                        url={`/interviews${item.url}`}
                                    />
                                </figure>
                            </Animate>
                        </li>
                        <li>
                            <div className="interview-item-details-holder">
                                <Animate delay={100}>
                                    <div className="border-animate border-white interview-item-details">
                                        <Animate delay={200}>
                                            <h3>
                                                {item.title}
                                            </h3>
                                        </Animate>
                                        <Animate delay={300}>
                                            <Button
                                                href={`/interviews${item.url}`}
                                                color="light"
                                            >
                                                Read more
                                            </Button>
                                        </Animate>
                                    </div>
                                </Animate>
                            </div>
                        </li>
                    </ul>
                ))}
            </div>
        </div>
    );
};

export default Interviews;
