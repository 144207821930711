import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Footer from '../../components/footer';
import Header from '../../components/header';
import Loading from '../../components/loading';

import { routes } from '../../constants/routes';

import SharedContext from '../../sharedContext';

const Layout = () => {
    const [activeSection, setActiveSection] = useState('');
    const [loading, setLoading] = useState(true);

    return (
        <SharedContext.Provider
            value={{
                loading,
                setLoading,
                activeSection,
                setActiveSection,
            }}
        >
            <Loading/>
            {!loading && (
                <>
                    <Header/>
                    <main>
                        <Switch>
                            {routes.map(route => (
                                <Route
                                    exact
                                    path={route.url}
                                    key={route.url}
                                >
                                    {route.component}
                                </Route>
                            ))}
                            <Redirect to="/"/>
                        </Switch>
                    </main>
                    <Footer/>
                </>
            )}
        </SharedContext.Provider>
    );
};

export default Layout;
