import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import News from '../../components/news';
import Team from '../../components/team';
import Intro from '../../components/intro';
import About from '../../components/about';
import YouTube from '../../components/youTube';
import Observer from '../../components/observer';
import CreatedBy from '../../components/createdBy';

import { scrollTo } from '../../helpers';

const Landing = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const slug = location.hash.split('#')[1];
            scrollTo(slug);
            history.replace(`/`);
        }
    }, [location.hash]); // eslint-disable-line

    return (
        <div className="page-layout">
            <Intro/>
            <CreatedBy/>
            <div>
                <Observer slug="about">
                    <About/>
                </Observer>
                <YouTube/>
            </div>
            <Observer slug="news">
                <News/>
            </Observer>
            <Observer slug="team">
                <Team/>
            </Observer>
        </div>
    );
};

export default Landing;
