import React from 'react';

import Landing from '../containers/landing';
import ComingSoon from '../containers/comingSoon';
import Interviews from '../containers/interviews';
import InterviewItem from '../containers/interviewItem';

export const routes = [
    {
        name: 'Home',
        url: '/',
        component: <Landing/>,
    },
    {
        name: 'Courses',
        url: '/courses',
        component: <ComingSoon>Courses are Coming Soon</ComingSoon>,
    },
    {
        name: 'Interviews',
        url: '/interviews',
        component: <Interviews/>,
    },
    {
        name: 'Interviews',
        url: '/interviews/:slug',
        component: <InterviewItem/>,
    },
    {
        name: 'Events',
        url: '/events',
        component: <ComingSoon>Events are Coming Soon</ComingSoon>,
    },
    {
        name: 'Merch',
        url: '/merch',
        component: <ComingSoon>Merch is Coming Soon</ComingSoon>,
    },
];
