import React  from 'react';
import { useParams } from 'react-router-dom';

import Title from '../../components/title';
import Animate from '../../components/animate';

import { interviews } from '../../content/interviews';

import './index.scss';

const InterviewItem = () => {
    const { slug } = useParams();

    const data = interviews.find(item => item.url.includes(slug));

    return !!data && (
        <div className="guide">
            <div className="static-interview-holder">
                <Animate>
                    <img
                        src={data.image}
                        alt={data.title}
                    />
                </Animate>
                <Title
                    line={false}
                    name={data.title}
                    reflection={false}
                />
                <div className="si-content">
                    {data.children.map((item, index) => (
                        <Animate key={index}>
                            {item}
                        </Animate>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InterviewItem;
