import React from 'react';

import To from '../to';
import Title from '../title';
import Button from '../button';
import Animate from '../animate';

import { conditionalSpread } from '../../helpers';

import './index.scss';

const url = '';

const content = [
    `Song Start was co-founded by Tamar Kaprelian (artist and activist) and Ali Tamposi (Grammy-Award winning songwriter) to provide free and global access to high quality music education. In partnership with Spotify and Noteable, Song Start is a one-stop-shop platform for lessons on Songwriting, Production, Music Business, and Mental Health.`,
    <>
        From 2016, to the time when the COVID-19 pandemic hit, Tamar was hosting in-person music education workshops for under-served creatives in emerging markets. Through her work in Founding <To target="_blank" href="https://www.billboard.com/articles/business/9434852/nvak-foundation-building-bridge-global-musicians-pandemic">Nvak Foundation</To>, she realized first-hand the barriers-to-entry that most creatives globally face when trying to enter the music business. She also quickly realized that COVID-19 would pose even greater barriers to digital access.
    </>,
    <>
        Tamar reached out to collaborate with friend and renowned songwriter, Ali Tamposi, who herself was working on super-serving under-served youth by way of her Foundation <To target="_blank" href="https://www.creativewavesfoundation.org">Creative Waves</To> and they teamed up to create Song Start -- an e-curriculum that would be made accessible to all creatives. Together, they’ve built this video and podcast series, with supplemental materials including lesson plans, syllabus, glossary, and demonstration videos, to empower aspiring musicians and industry professionals.
    </>,
    ...conditionalSpread([
        `Season 1 of Song Start features artists like Camilla Cabello, Sam Smith, Phoebe Bridgers, Hayley Kiyoko, John Legend, and more. Listen to their artist interviews by following the link below, and head to the Courses tab to begin the curriculum.`
    ], !!url, []),
];

const About = () => {
    return (
        <div className="about-section-holder">
            <div className="guide">
                <ul className="about-section">
                    <li>
                        <Title
                            line={false}
                            size="extra"
                            color="dark"
                            name="What is Song Start?"
                        />
                        <div className="about-video-holder">
                            <div className="about-video">
                                <Animate>
                                    <iframe
                                        width="560"
                                        height="315"
                                        src="https://www.youtube-nocookie.com/embed/RvtrzNOKLjo"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </Animate>
                            </div>
                        </div>
                    </li>
                    <li>
                        <ul className="about-section-copy">
                            <li>
                                {content.map((paragraph, index) => (
                                    <Animate key={index}>
                                        {paragraph}
                                        {content.length !== (index + 1) && (
                                            <>
                                                <br/>
                                                <br/>
                                            </>
                                        )}
                                    </Animate>
                                ))}
                            </li>
                            {!!url && (
                                <li>
                                    <Animate>
                                        <Button
                                            color="dark"
                                            href={url}
                                            target='_blank'
                                        >
                                            Access Season 1 Content
                                        </Button>
                                    </Animate>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default About;
