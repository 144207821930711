export const conditionalSpread = (spread, condition, fallback = []) => !!condition ? spread : fallback;

export const scrollTo = slug => {
    const element = document.querySelector(`[data-slug="${slug}"]`);
    if (element) {
        const headerSize = document.querySelector('header').getBoundingClientRect().height;

        window.scrollTo({
            top: element.offsetTop - headerSize,
            behavior: 'smooth',
        });
    }
};

export const inputValue = e => e?.target?.value;

export const requiredFormatter = (str, required) => required ? `${str} *` : str;
