import React from 'react';

import aftershow3jjs from './media/aftershow3jjs.jpg';
import aftershow4JJSlarge from './media/aftershow4JJSlarge.jpg';
import aftershow6JJS from './media/aftershow6JJS.jpg';
import aftershow2JJS from './media/aftershow2JJS.jpg';
import aftershow5JJS from './media/aftershow5JJS.jpg';
import jjsFeature from './media/jjsFeature.jpeg';
import polaroid3JJS from './media/polaroid3JJS.jpg';
import anikaFeature from './media/anikaFeature.jpg';
import aRSongStartHeader from './media/aRSongStartHeader.jpg';
import carl987065copy from './media/carl987-065-copy.jpg';
import carl987055 from './media/carl987-055.jpg';
import carl987106 from './media/carl987-106.jpg';
import carl746058 from './media/carl746-058.jpg';
import carl987100 from './media/carl987-100.jpg';
import carl746008 from './media/carl746-008.jpg';
import carl746101 from './media/carl746-101.jpg';
import carlysh_carlyshR202611A from './media/carlysh_carlysh-R2-026-11A.jpg';
import carlysh_carlyshR204420A from './media/carlysh_carlysh-R2-044-20A.jpg';
import carl987049 from './media/carl987-049.jpg';
import carl746080 from './media/carl746-080.jpg';
import polaroid4Cropped from './media/polaroid4Cropped.jpg';
import carlysh_carlyshR20187A from './media/carlysh_carlysh-R2-018-7A.jpg';
import carl746051 from './media/carl746-051.jpg';
import alexFeature from './media/alexFeature.jpg';
import ascarlyshcarlyshr1e012 from './media/ascarlyshcarlyshr1e012.jpg';
import ascarl746058 from './media/ascarl746058.jpg';
import ascarl746055 from './media/ascarl746055.jpg';
import asalexss from './media/asalexss.jpg';
import ascarlyshcarlyshr1e037 from './media/ascarlyshcarlyshr1e037.jpg';
import ascarlyshcarlyshr1e032 from './media/ascarlyshcarlyshr1e032.jpg';
import as949390010024 from './media/as949390010024.jpg';
import ascarl746066 from './media/ascarl746066.jpg';
import ascarlyshcarlyshr1e034 from './media/ascarlyshcarlyshr1e034.jpg';
import baileyLindley01 from './media/baileyLindley01.jpg';
import baileyLindley02 from './media/baileyLindley02.jpg';
import baileyLindley03 from './media/baileyLindley03.jpg';
import baileyLindley04 from './media/baileyLindley04.jpg';
import baileyLindley05 from './media/baileyLindley05.jpg';
import baileyLindley06 from './media/baileyLindley06.jpg';
import baileyLindley07 from './media/baileyLindley07.jpg';
import baileyLindleyFeature from './media/baileyLindleyFeature.jpg';
import aijiaGrammer2 from './media/aijiaGrammer2.jpg';
import aijiaGrammer3 from './media/aijiaGrammer3.jpg';
import aijiaGrammer4 from './media/aijiaGrammer4.jpg';
import aijiaGrammer5 from './media/aijiaGrammer5.jpg';
import aijiaGrammer8 from './media/aijiaGrammer8.jpg';
import aijiaGrammer10 from './media/aijiaGrammer10.jpg';
import aijiaGrammerFeature from './media/aijiaGrammerFeature.jpg';
import rosaFeature from './media/rosaFeature.jpg';
import rosaLinnMedia3 from './media/rosaLinnMedia3.jpg';
import rosaLinnMedia8 from './media/rosaLinnMedia8.jpg';
import rosaLinnMedia10 from './media/rosaLinnMedia10.jpg';
import rosaLinnMedia12 from './media/rosaLinnMedia12.jpg';
import rosaLinnMedia13 from './media/rosaLinnMedia13.jpg';
import rosaLinnMedia14 from './media/rosaLinnMedia14.jpg';
import rosaLinnMedia17 from './media/rosaLinnMedia17.jpg';
import rosaLinnMedia18 from './media/rosaLinnMedia18.jpg';
import rosaLinnMedia19 from './media/rosaLinnMedia19.jpg';

export const interviews = [
    {
        title: `Rosa Linn on the viral song that changed her life, meeting her musical heroes, and the advice she would give her past self`,
        url: `/rosa-linn-on-the-viral-song-that-changed-her-life`,
        image: rosaFeature,
        children: [
            <p>In the current age of music, artists dream of experiencing a new type of success by going viral on social media and expanding their audience with unprecedented speed. Rosa Linn, a singer-songwriter from a small town in Armenia, has had her entire world changed after the exposure that comes with a viral song. Her release, “SNAP,” transcended social media virality and landed her on top charts, coveted playlists, in rooms with musical heroes, and even a first-of-its-kind joint record deal between Columbia Records and Nvak Collective.</p>,
            <p>When solely viewing her discography, Rosa Linn seems like both an anomaly and an overnight success. With “SNAP” being the second song she ever released, Rosa Linn appeared to have experienced a shockingly quick success that artists can only ever dream of. But the truth of the matter is, her success was years in the making by a strategic and intentional team at Nvak Collective, a record label with the goal of making music careers more accessible for artists around the world. With Rosa Linn’s unwavering belief in her dreams coming to fruition, the partnership was a powerful match.</p>,
            <p>Growing up in a small town in Armenia with limited access to the music industry, the odds of experiencing global success as a musical artist would seem slim to none. Yet Rosa Linn defied all odds. After appearing on Eurovision in May 2022, Rosa Linn placed 20th in the contest. But her song, “SNAP” echoed loudly beyond the competition and was truly heard around the world. Becoming a viral sensation, peaking at #18 on Billboard’s Global 200 chart, currently sitting on week 20 on the charts, and surpassing 350 million streams on Spotify alone, “SNAP”’s viral moment has turned Rosa Linn’s dreams into reality.</p>,
            <p>Rosa Linn joined Song Start to share her inspiration behind the song that changed her life, and what she would have told the version of herself sitting on this song one year ago. She shares what it has been like spending time writing 7,000 miles from home in Los Angeles, how she pays homage to her home country, and what she is most excited for in the next year.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia10} alt='' />
                <small>*Rosa Linn on set of her music video for “SNAP”</small>
            </div>,
            <p><strong>Song Start:</strong> When and how did you get into the music industry?</p>,
            <p><strong>Rosa Linn:</strong> I was born and grew up in a small town in Armenia called Vanadzor. When I turned six my parents took me to a music school aside from my regular school. That's how I learned to play the piano. But as a kid guitar seemed more attractive to me, so I asked my dad to find me a guitar teacher too. I think I wrote my first song when I was around 10. And it felt so good to be able to express my feelings in something other than words.</p>,
            <p>I find comfort and so much joy in songwriting. Itt kind of gives meaning to my existence. So by just taking all the opportunities to perform, I was found by Tamar Kaprelian, currently my manager, who I started working with in 2019. We signed a publishing deal with Warner Chapell and the rest is history.</p>,
            <p><strong>SS:</strong> Congratulations on the success of your song, “SNAP.” How has your world changed since the release?</p>,
            <p><strong>RL:</strong> Thank you. My life has completely changed in just half a year. Sometimes it feels so surreal, like I'm in a cheesy movie. After “SNAP” went viral, I signed with Columbia and now I’m spending 90% of my time on the road, doing things I’ve never done in my life. Even though being away from home and mostly alone is hard, I am happy that all of my hard work and daydreaming has finally paid off. Now I’m slowly but surely checking all of the boxes on my bucket list.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia19} alt='' />
                <small>*Rosa Linn backstage before her show in Los Angeles, CA</small>
            </div>,
            <p><strong>SS:</strong> What was the inspiration behind the song and were you ever aware at the time the potential it would have to connect with so many people globally?</p>,
            <p><strong>RL:</strong> When I was 17 I fell in love with someone so deeply and it was unrequited. It was a very long lasting love story that I lived in my head. Plus other personal things were going on at that time and I was pretty broken mentally. That’s how the idea of snapping came to me. We all at some point in our lives want to, or do, snap. Because life, being so wonderful, can be very harsh.</p>,
            <p><strong>SS:</strong> You shared on social media that “SNAP” was originally written in 2019, three years before you performed it on Eurovision. Do you have any advice for other artists on exercising patience and choosing which songs to release?</p>,
            <p><strong>RL:</strong> I don’t think I’ve learned enough to advise on that yet. But I do know that when you first write a song, it can feel like the best thing you have ever written. But take time to listen to it again the next day, and then again the day after. If your song passes the test of time, then it is good. But just having a good song is a part of the job. In my case I have a label and managers to deal with the release strategies and all of those boring things. But I guess if it was up to me I wouldn’t be able to fight the urge to release the song right away and I would probably make lots of mistakes.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia18} alt='' />
                <small>*Rosa Linn on set of her music video for “SNAP”</small>
            </div>,
            <p><strong>SS:</strong> After spending the last few months in Los Angeles working on music, is there anything new you have learned about yourself as an artist during this process?</p>,
            <p><strong>RL:</strong> I am so lucky to have written with the people I have. The last two months of writing every day was a huge learning process for me. I worked with people who wrote some of my favorite songs ever and a couple of times I would be very nervous walking into a studio knowing I’m gonna write with the masters of the craft. But surprisingly those were the easiest sessions. And I would sometimes feel very exhausted and uninspired towards the end of this crazy run, but after hearing the demo, I’d think it was absolutely worth it.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia12} alt='' />
                <img src={rosaLinnMedia17} alt='' />
                <small>*Rosa Linn on set of her music video for “SNAP”</small>
            </div>,
            <p>I am a very impatient person especially when it comes to music, and I think these past two months made me less of that. The most important thing I have learned is to be who you are while in the room writing with others. Even if you are meeting your collaborators for the first time, staying open and being true to yourself and just acting normal saves so much time and makes things easier.</p>,
            <p><strong>SS:</strong> Your late night debut on The Late Late Show with James Corden was infused with many traditional Armenian elements and felt like an homage to your home country. How have you been able to balance the two cultures, U.S. and Armenia, in your music?</p>,
            <p><strong>RL:</strong> I can’t hide being an Armenian even if I’d want to. It’s just who I am. Most of my current self was formulated back home in Armenia, while eating the food, breathing the air, listening to our music, communicating the way we do and so on. I love Armenia so much and if I can do anything that can somehow bring more awareness of what’s going on in my country, I will.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia3} alt='' />
                <small>*Rosa Linn performing in Los Angeles, CA</small>
            </div>,
            <p>In my case it is including little details in my music videos or performances, or making a version of my songs with the traditional instruments or something else to spark an interest in people to learn more about my home.</p>,
            <p><strong>SS:</strong> What is your music making process like? Do you write alone, with others, a mixture of both? Walk us through how your songs started as thoughts and ended up being released.</p>,
            <p><strong>RL:</strong> I used to write alone a lot. First thing is to sit by the piano or guitar and start playing. Maybe I’d find something inspiring and then I’d write a song. Now most of my writing is collaborating with other songwriters. Everyone is different though. Sometimes we talk for hours and then start improvising, sometimes people just wanna get the song done. I differentiate between two kinds of writers— “hit songs factories” and “songwriters.” Either is fine with me. But I prefer working with the second kind.</p>,
            <p><strong>SS:</strong> In 2023, you will be touring with Ed Sheeran in North America—how did you feel when you found out and what are you most excited for?</p>,
            <p><strong>RL:</strong> I woke up one morning and the first thing I saw was a message from Tamar telling me I’m gonna be on tour with Ed [Sheeran]. I honestly thought she was just trying to make it happen, but then I read the paperwork and everything, and it just blew my mind away. I wouldn’t think that it would be possible this early in my career. That morning I was a kid again with a silly smile on my face, experiencing pure happiness.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia13} alt='' />
                <small>*Rosa Linn on set of her music video for “SNAP”</small>
            </div>,
            <p><strong>SS:</strong> Who are your biggest music influences?</p>,
            <p><strong>RL:</strong> Every song I’ve ever heard has influenced me in some way. I love Sting, Ozzy [Osborne], Coldplay, Adele, Zemfira, Aerosmith, Norah Jones, [Frank] Sinatra and so many more.</p>,
            <p>My musical taste is very broad. I can listen to Jazz, switch to Metal, go to Classical music, then listen to some psychedelic rock, some Armenian, Russian, Italian or French music, then listen to Sinatra, go back to Ozzy and maybe finish with a touch of indie rock. I mostly listen to sad songs, I cherish beautiful melodies the most.</p>,
            <p><strong>SS:</strong> This year you signed with Columbia Records, announced a tour with Ed Sheeran, released a song with Duncan Laurence and wrote songs with some of your music idols. What has it been like to be in rooms with artists that you have looked up to for years?</p>,
            <p><strong>RL:</strong> I will never stop appreciating everything that happened to me in 2022. I would never think that my career would develop this fast. Two years ago I was covering Duncan Laurence’s “Arcade” in my bedroom. Now we are good friends and we have a duet together. Or I was crying and still am to “Someone Like You” or ‘’Don’t You Remember” by Adele at night, and voila I’m in the room writing a song with Dan Wilson, the writer behind those songs. Or cutting vocals to a song that Diane Warren wrote and then getting emotional and crying with her listening to the demo.</p>,
            <p>If I had a bit less self control I’d simply go crazy.</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia14} alt='' />
            </div>,
            <p><strong>SS:</strong> What advice would you give yourself one year ago?</p>,
            <p><strong>RL:</strong> Wait!</p>,
            <p><strong>SS:</strong> When can we expect new music from you?</p>,
            <p><strong>RL:</strong> Beginning of next year I think we will release a single, and hopefully in spring we’ll release my first album that I’m beyond excited about. I can’t wait!</p>,
            <div className='i-images-holder'>
                <img src={rosaLinnMedia8} alt='' />
                <small>*Rosa Linn on set of her music video for “SNAP”</small>
            </div>,
            <p><strong>SS:</strong> What are you most looking forward to in the next year?</p>,
            <p><strong>RL:</strong> Album release of course and I can’t wait to be on tour with Ed [Sheeran], performing in arenas and stadiums. As an artist the only reason I do what I do is to get to perform my songs for an audience. It is the best feeling in the world to hear people singing back to me while I’m singing my heart away. It feels liberating.</p>,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Find Rosa Linn on socials <a href='https://www.instagram.com/rosalinnmusic/?hl=en' target='_blank' rel='noopener noreferrer'>@rosalinnmusic</a>. Follow along for updates on musical releases, shows, and more.
                </em>
                <br />
                <br />
                <em>Photos and words by <a href='https://www.instagram.com/carlyjeansharp/' target='_blank' rel='noopener noreferrer'>Carly Sharp</a>.</em>
                <br />
                <br />
                <em>Learn more about songwriting and artistry from Song Start— recommended episodes:</em>
                <br />
                Video: <a href='https://noteable.spotify.com/songstart/hooks-with-ali-and-watt' target='_blank' rel='noopener noreferrer'>Ali Tamposi and Andrew Watt Break Down Hooks in Songwriting</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/inspiration-and-collaboration-chaz-cardigan' target='_blank' rel='noopener noreferrer'>Chaz Cardigan on Inspiration, Co-Writes, and Serving the Song</a>
            </p>,
        ],
    },
    {
        title: `INSIDE THE SESSION: Aijia Grammer`,
        url: `/inside-the-session-aijia-grammer`,
        image: aijiaGrammerFeature,
        children: [
            <p><em>The pandemic forced a change within the music industry that has granted songwriters unprecedented flexibility and access to peers around the globe. In 2020, many songwriters, producers, and artists shifted their traditionally in-person sessions to remote. Aijia Grammer welcomes Song Start into her home to discuss how this change has influenced the trajectory of her career.</em></p>,
            <p><em>Welcoming her second child during the first month of the pandemic, Ajia Grammer, an LA based singer-songwriter, felt she was given a unique opportunity to be close to her kids while continuing to create music she was passionate about. Two major life goals that often are at odds with one another but that she was unwilling to compromise on. Setting up a home studio and mastering the work-from-home flow via Zoom allowed her to collaborate with other musicians around the world without having to forfeit quality time with her family.</em></p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer5} alt='' />
                <small>*Aijia Grammer at home in Los Angeles</small>
            </div>,
            <p><em>Grammer has refined her home studio set-up and went through over a year of trial-and-error to be able to share her specific “do’s & dont’s” of Zoom sessions and the best way to foster a creative, vulnerable space through the screen. She brings Song Start inside her studio to share her well of knowledge— hoping to empower the next generation of songwriters with a tangible guide and the mindset shift she believes you must have to make it in music.</em></p>,
            <p><em>A swift multitasker, Grammer has a baby on her hip while she maneuvers around her kitchen, quickly blending her go-to morning smoothie and sharing her thoughts on all things music. Something the home is filled with fondness for— shared between her and her husband and now their children, music is woven through their daily lives. The day provided glimpses of these traditions uniquely related to at-home songwriting sessions— Grammer’s eldest daughter enters the studio at the end of a session to jump and dance to the music that was created that day. The love for music echoes in their home.</em></p>,
            <p><strong>Song Start:</strong> What has your experience in the music industry been like?</p>,
            <p><strong>Aijia Grammer:</strong> My experience in the music industry is very multifaceted. But overall, the feeling that I have about the music industry is anyone can do anything if they work really hard. I am a firm believer in work ethic over talent. I think that there’s so many talented people. People come here [to Los Angeles] because they’re talented but the people who really succeed are the ones that just grind and never give up and are really friendly and make friends everywhere and treat people well. And the people that come here and are constantly asking themselves “what can I do to be of service” are the ones that win. A lot of the energy that I see sometimes in LA is taking energy, [...] and I find the people that really kill here are the ones that are always being of service and finding ways to be helpful. [...] Find your community, find your people, treat them well and work really hard.</p>,
            <p><strong>SS:</strong> What is your favorite part of your job?</p>,
            <p><strong>AG:</strong> My favorite part of my job is just meeting different people and finding ways to tell the truth with new people. Because a lot of what a session is, basically, is just a blind date. Now some blind dates aren't awesome, but it's really fun to go on a bind date with a goal in mind and you're like, ‘I’m showing up, I'm gonna bring all that I have, I’m gonna be positive, I’m gonna support this person that I don't know, and at the end of the day were going to have a piece of art that wasn't there before. It didn't exist four hours ago and then we're going to have that at the end.’ And I think that's really cool and really intimate and vulnerable to do with someone that you just met. I try to just go right in with people from the beginning. [...] I want to go in on the real stuff right from the beginning and that is invigorating with a stranger. It just is, it’s wild.</p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer2} alt='' />
                <small>*Aijia Grammer in an online songwriting session from her home studio</small>
            </div>,
            <p><strong>SS:</strong> How do you manage your work-life balance?</p>,
            <p><strong>AG:</strong> I think my tips for work-life balance are to be really organized with your partner and the other people in your house about what you need and what hours are happening and just really coordinating everything. My husband also works in the music industry, he’s an artist, singer, and his name is Andy Grammer. He’s awesome. But pretty [early] on in the pandemic, we found that we both needed our own separate places to make music and they couldn't cross into each other as far as noise. We decided that one of us needed to go out of the house. I decided that I wanted to stay in the house because I also had a baby in this pandemic.</p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer8} alt='' />
                <small>*Aijia Grammer at home in Los Angeles</small>
            </div>,
            <p>For me, it made sense to take this home studio space because I wanted to be a mom, I was nursing, but I had also just signed with my management, who’s awesome, and I was eager to get rolling but also wanted to be home with my child. For me, the timing kinda worked out that it was perfect because everybody had to switch to Zoom. [I would] do a session and whenever my baby needed to nurse, my mom would knock on the door, I would turn the camera up a little bit, someone would hand me an infant, and I would just be straight up nursing and we would keep it moving. And we would be talking about the second verse and it just totally worked. I was unwilling to compromise about being a mom, that was a big part of my life goals so we made it happen. I did the most amount of co-writes I've ever done in a year in that year so it kind of all worked out. You can do it!</p>,
            <p><strong>SS:</strong> How do you get in the right headspace for a songwriting session?</p>,
            <p><strong>AG:</strong> I do have some weird routines to get myself in the zone. I think that it's really important to psych yourself up. A big part of being a songwriter in my opinion is just fighting self-doubt and fear and imposter feelings. I'm into all the spiritual stuff, I love to light a candle, I love to say a prayer before my session, I love to remember that I’m just a channel and I’m not supposed to be some brilliant genius all I’m supposed to do is just sit here, be a good time, be open, and do my best. So that's really the most important part of my routine, is reminding myself that I'm just a channel and I'm not the thing.</p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer10} alt='' />
                <small>*Aijia Grammer’s daily mantra to remind herself of her growth and to ground herself in gratitude. <br /> “Remember when what you have now was only a dream?”</small>
            </div>,
            <p><strong>SS:</strong> How do you find the fun and human connection in a Zoom session?</p>,
            <p><strong>AG:</strong> I’m very inspired by the beat so I often like for us to come up with a beat or a groove first. Because I personally feel inspired by the groove. The first thing we do is figure out what the tempo is going to be. We’ll talk about like what is our mood today? That’s the first thing we figure out and that is what excites me.</p>,
            <p>I have a memo in my phone of ideas of songs and concepts that I am just constantly adding to. And I look if anything I have fits the vibe of what we are going to do. And if it doesn't, we don't use it. We talk about something, maybe the other person has a concept, maybe their concept is better and then we do that. And I always go towards where the flow is going. If the flow is going somewhere, I follow the flow. It's not about my idea or your idea, it doesn't matter. It’s just where it is moving easily and then we use that.</p>,
            <p><strong>SS:</strong> What tips do you have for Zoom etiquette?</p>,
            <p><strong>AG:</strong> I have so many tips for Zoom etiquette. Be on time. It's kind of annoying when people are on Zoom at the time you agreed on and they're just waiting there. So, be on time. Make sure your phone is on silent. It's, to me, really kind of rude and a no-no if someone is checking their phone the whole time. It's better to just be like ‘hey, I need to handle something really quick give me two minutes’ than being on your phone on-and-off the whole time. I've written with a lot of people who do that and I don't care how good of a writer they are, it puts a really weird energy on the session.</p>,
            <p>Also, being really aware that you have to mute in order for people to do things on their end. So, throughout a session we are constantly muting and unmuting. And that really does help the flow. So that if we say ‘okay, I'm going to do my thing for a minute you're going to do your thing,’ and then we mute so he isn't hearing my ideas when he is working on his. Those are some of the key etiquette things.</p>,
            <p>Also, [general session tip], just being courteous and making sure you're not vetoing everything the other person says. Whatever they suggest, really taking it in and hearing it and making the flow good so you're not shutting people down too much. Because if you shut people down too much, they feel vulnerable and don't want to offer more ideas.</p>,
            <p><strong>SS:</strong> With online sessions, it seems imminent that some get canceled last minute. What does it feel like when this happens?</p>,
            <p><strong>AG:</strong> There's two things that happen when a session gets canceled. The first one is my imposter syndrome, and my fear, and my comparison of myself, all the bad things that get in the way of creating music, they sigh of relief. Because I don't have to show up and face anything today. That's my first instinct. And then my second thought is “dang, I got myself ready, I got myself dressed, I made sure my kids were being watched, I did all this prep and now I'm not going to have to go create.” Sometimes what I’ll do is still go create. I'll hit up all my buddies in my phone and be like I just had a session canceled, want to write? So there's always something to do and the good thing with being home is like, ‘okay let me redirect and shoot that energy at other things I need to do.’ There's a million things to be done.</p>,
            <p><strong>SS:</strong> When and how do you cancel a session gracefully, if ever?</p>,
            <p><strong>AG:</strong> So things come up and sometimes you have to gracefully dip out of a session. The way that I do that is I always personally try to make sure it's at least 24 hours ahead, usually more. I think that the etiquette is always to make sure that it's 24 hours ahead of time and you’ve made sure that the person got the email or text. I don't ever want there to be a situation where a person is just waiting on a Zoom for me, or in person, and I'm just not showing up. It's your job to communicate that you're not coming. I don't care how big you are. I don't care if you are the most famous person on planet Earth. I don't care if you have all the team members. You're the one that looks bad if you don't show up. Make sure the person got the message. And the other part of it is making amends. Follow it up with the other dates soon so that the other person doesn't think you're flaky because so many people around here are flaky. I think the biggest thing is being true to your word.</p>,
            <p><strong>SS:</strong> What advice do you have for finding collaborators?</p>,
            <p><strong>AG:</strong> The world is your oyster on social media. So if you're looking for collaborations, I highly suggest just [direct messaging] people. You pretty much can reach anyone these days via Instagram or TikTok. I have no shame in my game. I [direct message] people all the time, the worst thing they're going to say is ‘no’ or don't respond. And often they say ‘yes’ or they're like ‘send me some music, let me see what you're about.’ And I think that's a really legitimate way to network these days. There's often times that my manager or publisher will suggest people to write with and that's great too but I didn't have management or anyone for a very long time and you can just reach out to people now and I think that's the most viable way to find a collaborator.</p>,
            <p><strong>SS:</strong> Which music editing software do you use?</p>,
            <p><strong>AG:</strong> The software I use is Logic, I feel like it's the simplest if you are a Mac user. I was one of those people that took GarageBand way too far because I had a mental block about learning something new. And like I said, in this pandemic, it sort of forced me to step up my game and really dig into Logic and for me that was the easy step up. A lot of people I work with, they're on Ableton, some people are on Pro Tools. There's really no special answer, it's whatever you feel comfortable in.</p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer3} alt='' />
                <small>*Aijia Grammer in her home studio</small>
            </div>,
            <p><strong>SS:</strong> Tips for staying organized?</p>,
            <p><strong>AG:</strong> I think for me prioritizing work-life balance works the best if I have a routine and a sort of schedule. So even though I am working from home and most days I am just walking from the kitchen to my home studio, what I do is I say Monday Wednesday Friday I do sessions. Some people do Monday through Friday. I don't operate like that. I really feel like I need time to fill the well of ideas and live my life and I know I need a day in between to decompress. So that's my schedule and I'm pretty strict with it and I don't mess with that too much. If someone cancels, I work on Logic, I cut vocals, I tweak a mix, I look over lyrics of something or I practice piano. There's always something to be done so those are my time frames that I do that. So on Tuesday when I don't work, I don't work. I'm with my kids, I have a dentist appointment. Maybe there's something I want to work on for pleasure. It's for my soul. For myself. There's still music happening but it's more for fun and so I know I'm accountable Monday, Wednesday, and Friday to myself and to whoever I made the appointment with.</p>,
            <p><strong>SS:</strong> What change do you wish to see in the music industry?</p>,
            <p><strong>AG:</strong> Something I wish to see change, which is a very easy answer is equality. The equality of women in the production elements in this industry. And the equality of people of color just being afforded more opportunities. And the equality of women of color especially. There is undoubtedly a bias towards men in the production field in the creation of music. The writers need people producing, mixing, vocal producing, all these people tend to still be male. And often white male. And I think we have a long way to go in that regard. And I feel personally responsible to work with and support as many women and people of color as I can.</p>,
            <p>My sort of main north star has been working with women. And trying to find ways to create more opportunities for women. You know, if I can shortcut any of the things I did the long way to the next person coming up, that only helps all of us. I think it's really important to help all the people around you succeed. Because we are a collective, we are an era of the music industry. We look back and we listen to the music of the 60s, the Temptations and all that, all the stuff that came out of Motown— that was a scene that was a crew! We are that for right now. I want to make sure that I am representing us well and that I am helping speed up the process for people who were me in the early stage because I think there's so much inequality that if I can help in any way and bridge the gap by mentoring or showing [something, I will], I just want to see women kill in this business.</p>,
            <div className='i-images-holder'>
                <img src={aijiaGrammer4} alt='' />
                <small>*Aijia Grammer in her home studio</small>
            </div>,
            <p><strong>SS:</strong> What advice do you have for someone entering the music industry?</p>,
            <p><strong>AG:</strong> My advice to anyone entering the music industry in whatever field you're entering, production, instrumentalist, singer, any field, is be a kind person, treat everyone really well and work so hard. Be unstoppable. If you are an unstoppable force, you will succeed here. That's just how it works. I think it's also important to be open to learning and be open to know that you might not know everything and that's okay because we're all learning. Even the most skilled person on the planet still has something to learn. And when you're open to being taught in all circumstances but also bringing that with working hard and being kind to everyone, you will win. That's a fact.</p>,
            <p>I will always take the hard working person over the talented person because talent doesn't do anything for me if you are flaky and you don't treat people well. So be cool, be nice, show up to your sessions with a good attitude, have done a little bit of research, have some ideas in your back pocket. [...] Especially in writing, something that really helps you win is if you are able to listen and not just output. Take in what the other people are saying, they're offering suggestions too and everything they're saying you can learn from. All my best tricks I got in sessions from other people. So, yeah I have an idea and I want to be heard but my idea is not going to trump whatever I'm going to learn in that session that might be my new little trick to take to the next thing. So always be open, be kind, and work hard.</p>,
            <h3>AIJIA’S HOME STUDIO RECOMMENDATIONS:</h3>,
            <p><small>*The make or model that Aijia uses is listed below each item</small></p>,
            <ol>
                <li>
                    <p>Interface</p>
                    <ul>
                        <li>Apollo Twin X by Universal Audio</li>
                    </ul>
                </li>
                <li>
                    <p>Speakers</p>
                    <ul>
                        <li>Yamaha — but a portable option is headphones</li>
                    </ul>
                </li>
                <li>
                    <p>Midi Keyboard</p>
                    <ul>
                        <li>Line 6</li>
                    </ul>
                </li>
                <li>
                    <p>Microphone</p>
                    <ul>
                        <li>Shure SM7B</li>
                    </ul>
                </li>
                <li>
                    <p>Headphones</p>
                    <ul>
                        <li>Audio-Technica and Sony</li>
                    </ul>
                </li>
                <li>
                    <p>Computer</p>
                    <ul>
                        <li>Laptop or Desktop</li>
                        <li>iMac desktop</li>
                        <li>Mouse & Keyboard for desktop</li>
                    </ul>
                </li>
                <li>
                    <p>Cables</p>
                    <ul>
                        <li>XLR, Quarter-inch, etc, connect the proper cables and ports</li>
                    </ul>
                </li>
            </ol>,
            <p><strong>Watch Aijia break down her home studio <a href='https://youtu.be/ZG5d9mU3XyE' target='_blank' rel='noopener noreferrer'>here</a> and a <a href='https://youtu.be/zg0WgEwRlro' target='_blank' rel='noopener noreferrer'>BTS video</a> inside her session!</strong></p>,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Find Aijia Grammer on all socials @aijiaofficial.
                </em>
                <br />
                <br />
                <em>Photos and words by <a href='https://www.instagram.com/carlyjeansharp/' target='_blank' rel='noopener noreferrer'>Carly Sharp</a>.</em>
                <br />
                <br />
                <em>Learn more about production and the creative process from Song Start — recommended episodes:</em>
                <br />
                Article: <a href='https://noteable.spotify.com/songstart/top40-theorys-glossary-of-music-terms' target='_blank' rel='noopener noreferrer'>Terms for your Songwriting ToolBox</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/artistry-identiy-faouzia-abir' target='_blank' rel='noopener noreferrer'>Artistry and Identity with Faouzia and Abir</a>
            </p>,
        ],
    },
    {
        title: `Bailey Lindley on experimenting with production style, finding trustworthy collaborators, and chasing your excitement in music`,
        url: '/bailey-lindley-on-experimenting-with-production-style',
        image: baileyLindleyFeature,
        children: [
            <p><em>For producer and songwriter, Bailey Lindley, being a producer often looks like being “in service of the song and in service of the artist” while creating music. In doing so, he’s committed to hearing everyone out during a session and never closing off anyone’s ideas, all while ensuring the final product supports the artist’s vision. He shares with Song Start how he got his start in the music industry, what his creative process is like, and why he believes trial-and-error is paramount to finding your style as a producer.</em></p>,
            <p>Lindley is a producer and songwriter born and raised in Los Angeles. He attended NYU where he studied music— quickly realizing that the gap between music school education and real world industry etiquette was incredibly vast. Learning how to do something correctly in a classroom setting doesn’t immediately translate to or guarantee success outside of it. For Bailey Lindley, much of his practical and stylized knowledge has derived from hands-on experiences in the studio with established industry professionals.</p>,
            <p>During his freshman year of college, Lindley interned for a prominent pop producer and felt he was able to gain invaluable experience and knowledge from studying someone successful in his hopeful path. He explains that the difference in what he was learning from the institution versus the source was at times, jarring but vital to his growth. Since his time in New York, Lindley has moved back to California and has produced with and for artists including G-Eazy, Omar Apollo, Isaac Dunbar, Annika Rose, Tom the Mailman, Jazmine Sullivan and more.</p>,
            <p>Lindley joins Song Start to share his experience at NYU’s Clive Davis Institute of Recorded Music, the love for music that got him there, and ultimately why he dropped out to pursue a full-time career centered around music production.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley01} alt='' />
                <small>*Bailey Lindley and collaborators, Annika Rose, Alex Salibian, and Ethan Schneiderman in a session</small>
            </div>,
            <p><strong>Song Start:</strong> When did your interest in music begin?</p>,
            <p><strong>Bailey Lindley:</strong> I started playing guitar at a very young age, I think around like six or seven. And I had someone put the idea in my head that if I played guitar I would get girls.</p>,
            <p>And I think they were saying it as an offhand joke but I took that really seriously. And I was like, “Okay, cool. Got it. Thank you. I'll get on that as soon as possible.” My brother got a guitar for Christmas, the year prior and it had just been sitting around the house.</p>,
            <p>So I went back, picked it up, and started taking lessons. And that kind of introduced me into music as a whole. I think also at that age, I had so much time on my hands that immediately it became an obsession.</p>,
            <p><strong>SS:</strong> Building off of that, walk us through your career a little bit. The path to being a producer can seem abstract and tends to be different for everyone. So how did that initial desire of learning guitar as a kid turn into a tangible career and not just a hobby?</p>,
            <p><strong>BL:</strong> Through the years, what became clear was that performing wasn't necessarily the most exciting part for me. I think I really liked experimenting with, coming up with little ideas, coming up with little songs. And that was what always got me the most interested. When I learned that there was a career that was centered around what I liked about music, I was pretty excited.</p>,
            <p><strong>SS:</strong> So then what would you say were your first steps into making this a career and going from “Oh, I could be a producer” to “actually I am a producer.”</p>,
            <p><strong>BL:</strong> Good question. Sometimes I don't know. But I think I got really lucky to be introduced to Benny Blanco and his two managers and business partners. I got introduced to them through a friend named Blake Slatkin. I went over there to start doing intern tasks and stuff like that. And I think seeing the way those guys operated and seeing that there was a real career behind producing. Seeing that there was money coming in, there were real songs being worked on and that there was a physical office— it made it feel like a real thing. And that I could start to learn at the very, very, abstract basics of how to do it.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley02} alt='' />
                <small>*Bailey Lindley and collaborators, Paul Phamous, Alex Salibian, and Ethan Schneiderman in a session</small>
            </div>,
            <p><strong>SS:</strong> Did you go to school for music?</p>,
            <p><strong>BL:</strong> I went to Clive Davis at NYU. And everyone that I started to meet in the real music industry was around the time that I got to school. Which was tough at times because it felt like there was the stuff that I was learning from school on one side and then the stuff that I was learning from being around those industry professionals, which was miles apart, truthfully. What became pretty apparent to me quickly was that the stuff I was learning in school wasn't actually applicable.</p>,
            <p>And then after three years, I dropped out. I had four credits left and I could have finished in a summer. I listened to the audio book, No One Gets Out of Here Alive: Biography of Jim Morrison and it talks about him dropping out two weeks before his graduation from UCLA.</p>,
            <p>And I was like, “fuck it, I'm out.” So I dropped out. My parents were not excited. I had just had it with school because it was taking so much time away from actually making music.</p>,
            <p><strong>SS:</strong> So is there anything that you felt like you were getting from the people that you were supplementing your education with or that you learned on your own that you were not getting from music school?</p>,
            <p><strong>BL:</strong> Yeah, I think the truth is music school can teach you a lot about music, in terms of the technical skills that you need, like music theory, or maybe about how to use equipment or programs or stuff like that, which in and of itself is pretty difficult because everyone enters that situation on different levels of understanding.</p>,
            <p>So they can teach you those technical skills a little bit, but so much of this is the social aspect. Being around real people in the music industry that started to click for me very quickly. I was like, “Oh wait, this isn't just, who can make the best song? This is about who can make the best song in the right place at the right time with the right collaborators and then send it to the right person.”</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley03} alt='' />
                <small>*Bailey Lindley with Paul Phamous outside of the recording studio</small>
            </div>,
            <p><strong>SS:</strong> What is your music making process like?</p>,
            <p><strong>BL:</strong> It's so different every time. Especially being a producer, I'm in service of the song and in service of the artist. So sometimes that means being the loudest person in the room and getting everyone super excited so that you're bringing out a good performance. Sometimes it means shutting up and letting something that's already happening happen.</p>,
            <p>If I was going to joke about it, I'd say “it's just basically a bunch of overthinking until we can figure out how to stop overthinking and then be like, ‘Oh, maybe there's a song here.’”</p>,
            <p><strong>SS:</strong> Being the producer in a session, you have to balance so many opinions. How do you navigate that and how do you bring the artist's vision to life amongst all of that?</p>,
            <p><strong>BL:</strong> Obviously the corny thing we want to say is like, “Oh, it's all about what the artist wants.” And in truth, sometimes the artist wants something kind of bad.</p>,
            <p>So it's tough, but I think I've learned a lot from my mom. My mom's a therapist. She has an incredibly understanding, calm, perspective that can hear a lot of differing opinions and bring a sense of balance to all of them. But I think ultimately, it's about hearing everyone out, even if you're not going to fully execute an idea, or even if you disagree. In music, like in life, truly one of the things everyone wants the most is just to be heard.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley04} alt='' />
                <small>*Bailey Lindley with Annika Rose while recording music at The Village Studios</small>
            </div>,
            <p>If someone's really adamant about one part of a song being a certain way, hear them out, give it a try. Because the truth is no one in the room actually knows. Like there might be someone who's more experienced, there might be someone who has bigger songs. But no one knows it all.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley05} alt='' />
                <small>*Bailey Lindley with Annika Rose outside of the recording studio</small>
            </div>,
            <p>I think it's experimentation until you land on something that everyone in the room is somewhat happy with or very happy with.</p>,
            <p><strong>SS:</strong> How have you found collaborators that you trust and enjoy making music with?</p>,
            <p><strong>BL:</strong> Through pure trial and error. For a lot of people it's coming to LA. For me, I'm from LA so it was coming back to LA after I went to school in New York.</p>,
            <p>Just working with like a million people. When you’re in a room with someone, ask yourself “does this person make me feel comfortable? Do we see eye to eye? Do we have similar tastes?” I've worked with a bunch of people who I've loved and I'd hang out with, I'd get dinner with them, no question, but they may not be the thing that I need creatively.</p>,
            <p><strong>SS:</strong> Do you have any advice for finding your style as a producer?</p>,
            <p><strong>BL:</strong> Not to give the same answer but I think it is trial and error truthfully. There's a lot of experimentation necessary. If you feel that little tingle of excitement, whatever you're working on, if you're excited about it, there's bound to be other people excited about it. Might not be the first group of people you play it for, but just chase the excitement.</p>,
            <p><strong>SS:</strong> Over much of the last year you've been working with your girlfriend and frequent collaborator, <a href='https://www.songstart.org/interviews/annika-rose-on-the-making-of-her-sophomore-ep' target='_blank' rel='noopener noreferrer'>Annika Rose</a>, on her upcoming releases. What has that experience been like?</p>,
            <p><strong>BL:</strong> The thing that comes to my mind is, when you work with people that are good, it's just so much easier. As a producer, you know, there's things that get difficult and challenging at times, but when you have the foundation of a person who is the right mix of talent, some element of connection with their audience, they have something going for them, some sort of style or swag that's attractive on any level.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley06} alt='' />
                <small>*Bailey Lindley with collaborators, Paul Phamous & Annika Rose outside of the recording studio</small>
            </div>,
            <p>When you work with people that are really good, it just flows so much more smoothly and obviously there's an added layer of being in a relationship and what that brings.</p>,
            <p>And to speak on that, I think what that brings is that I know her so well. I've said this to other people in the past, but I know what her voice sounds like when she's at a party and she's being polite, and I know her voice sounds like when she's crying to her mom on the phone.</p>,
            <p>And I know what her voice sounds like when she's laughing with a childhood friend. There's a certain element of authenticity I can pick up on or I can help promote in her music. And luckily for her, the most effortless and simple, authentic expression of herself is so fucking good.</p>,
            <p><strong>SS:</strong> What are you most looking forward to over the next year?</p>,
            <p><strong>BL:</strong> It's almost sad not having an answer for that. The truth is I just don't think that far ahead. I've tried so hard in my life to be more present with the current moment. In terms of the next two weeks, I’m thinking about “what do I have to do with this project? What could I do to make this thing move forward,” whatever it is.</p>,
            <p>Honestly, I love what I do and I'm pretty excited to do it all the time. I'm gonna wake up tomorrow, make music, see friends, it's gonna be sick.</p>,
            <div className='i-images-holder'>
                <img src={baileyLindley07} alt='' />
                <small>*Bailey Lindley & Annika Rose on the set for her music video for an upcoming single</small>
            </div>,
            <p><strong>SS:</strong> That's honestly ideal. What advice do you have for aspiring producers?</p>,
            <p><strong>BL:</strong> I think to answer this question, you almost have to sort of assume the perspective that you know what you're talking about. And I hope that I never think that I know what I'm talking about. Someone told me once, “be a student of the game, not a master of the game.”</p>,
            <p>I’m clearly still fairly young and figuring it out day-by-day. So I don't know if there's really specific advice.</p>,
            <p>The one thing I could say is to exercise as much patience as possible. And also try your hardest to be durable through hard times. I think every producer that I've looked up to has inevitably experienced a period of hardship, whether it comes at the beginning or in the middle or wherever, and truthfully usually multiple periods of that.</p>,
            <p>So I've started to think as I've gotten older that there's no one thing that really makes a person able to be great in music. It's almost like a rodeo and the more that you can hang on and not get bucked, the better your chances are that something happens.</p>,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Find Bailey Lindley on Instagram <a href='https://www.instagram.com/baileylindley/?hl=en' target='_blank' rel='noopener noreferrer'>@baileylindley</a>. Follow along for updates on his musical projects & releases.
                </em>
                <br />
                <br />
                <em>Photos and words by <a href='https://www.instagram.com/carlyjeansharp/' target='_blank' rel='noopener noreferrer'>Carly Sharp</a>.</em>
                <br />
                <br />
                <em>Learn more about production and the creative process from Song Start — recommended episodes:</em>
                <br />
                Video: <a href='https://noteable.spotify.com/songstart/building-a-demo-tokimonsta' target='_blank' rel='noopener noreferrer'>TOKiMONSTA on Building a Demo</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/the-production-process-take-a-daytrip' target='_blank' rel='noopener noreferrer'>Take a Daytrip on their Production Process</a>
            </p>,
        ],
    },
    {
        title: 'Alex Salibian on persistence as a producer, gaining confidence instead of losing it, and the evolution of his creative process',
        url: '/alex-salibian-on-persistence-as-a-producer',
        image: alexFeature,
        children: [
            <p><em>Producer, songwriter, and music business executive, Alex Salibian, has had an eventful year— from launching an independent record label hyper-focused on changing the landscape of the music industry to creating records and building Web3 experiences with artists. He joins Song Start to talk about his journey as a producer and showing up at every stage of the process— from joining the Billions Club on Spotify to supporting an artist through their break-out hit.</em></p>,
            <p>What separates a ‘hit’ from the rest? With an average of 60,000 new songs uploaded daily to Spotify, just one of many streaming platforms, how does a hit stand out amongst the noise?</p>,
            <p>Alex Salibian, the Chief Operations Officer of Nvak Collective, is also a working producer credited on "Sign of the Times" by Harry Styles and "Snap" by Rosa Linn, two hits that have recently surpassed numeric milestones — one billion streams and one hundred million streams respectively.</p>,
            <p>The music multihyphenate shares a stand-out trait that he believes all popular tunes share. There’s no way to know for sure how an audience will receive the work, but Salibian is sure that a hit “doesn’t get tired.” Elaborating that everytime you listen to the song, it should make you feel something. Salibian has found this marker as a constant throughline in songs that resonate with the masses.</p>,
            <p>Joining Song Start, Salibian shares his three mindset fundamentals as a producer, how he entered the music industry, and the evolution of his creative process that has allowed him to stay in it long-term.</p>,
            <div className='i-images-holder'>
                <img src={ascarlyshcarlyshr1e012} alt='' />
                <small>Alex Salibian in the studio with collaborator, Paul Phamous</small>
            </div>,
            <p><strong>Song Start:</strong> When did your interest in music begin?</p>,
            <p><strong>Alex Salibian:</strong> Well, my interest in music and my experience in music didn't start at the same time. I started taking piano lessons when I was six and I hated it up until I went to college. And when I went to college and was alone and wasn't taking piano lessons anymore, I got a friend of mine to make a copy of the key for the practice room in the music school.</p>,
            <p><strong>SS:</strong> That's awesome.</p>,
            <p><strong>AS:</strong> I would go and I'd have the piano to myself and that's when I was like, ‘oh shit, this is great. And there is something I can do here. And this is something that I really love.’ I never had a feeling that I would make it something for my life until that moment, freshman year of college.</p>,
            <p><strong>SS:</strong> Will you walk us through your career? The path to becoming a producer can seem abstract. So how did you turn your initial desire freshman year into a tangible career?</p>,
            <p>AS: I panicked senior year because I was a Sociology major and didn't have an internship [in music] and was graduating at the end of the year. I happened to drive by the Universal Music building in Santa Monica. And thought it looked pretty, that was it. So I went back to my apartment and I went to <a href='https://www.universalmusic.com' target='_blank' rel='noopener noreferrer'>www.universalmusic.com</a> and applied for an internship.</p>,
            <p>A week later, I got a call to come in for an interview. I didn't ask them what department. When I showed up, I had no clue where I was interviewing. The security looked me up and I ended up going to Neil Jacobson's office. Neil is a well known manager and he was managing and is managing a producer named <a rel='noopener noreferrer' target='_blank' href='https://open.spotify.com/playlist/37i9dQZF1EFRR9aqyxC7QL?si=d8993d7c294944ee'>Jeff Bhasker</a>. At the time, I didn't know any of this.</p>,
            <p>When I graduated, Neil didn't have a job for me in the building. But he said, ‘I do have some other job you can take.’ And it was Robin Thicke’s personal assistant.</p>,
            <p>I had no other options and wanted to stay in the mix. So I became Robin Thicke’s personal assistant. And it happened to be during the time when he was working on "Blurred Lines" with Pharell. But it wasn't taking me where I wanted to go so I decided to study for the GMATs instead.</p>,
            <p>I was bugging Neil constantly to let me back into Interscope [Records]. I was on my last month of rent before having to move back home to Long Beach when Neil called me and was like, ‘Hey, I got a temp position for you.’</p>,
            <div className='i-images-holder'>
                <img src={ascarl746058} alt='' />
                <small>Alex Salibian in the studio with Annika Rose and bandmates</small>
            </div>,
            <p>Neil needed someone to deal with all of Jeff Bhasker’s phone calls after his nomination for Song of the Year (“<a href='https://open.spotify.com/track/7a86XRg84qjasly9f6bPSD?si=1279fcab15f34ee2' rel='noopener noreferrer' target='_blank'>We Are Young</a>”) and Album of the Year (<a href='https://open.spotify.com/album/7iycyHwOW2plljYIK6I1Zo?si=AQcBaRndTzyutCps_sX5Tw' rel='noopener noreferrer' target='_blank'>Some Nights</a>) for the fun. record. And that's when it clicked. I was like, ‘oh shit, like I’ll be working for one of the best producers on the planet.’</p>,
            <p>He had no clue I was into music, but he just liked the way I was dealing with his phone calls. And so I just got to work being the best assistant I could possibly be like cleaning, setting up the fridge, bringing coffee to sessions.</p>,
            <p>And in the meantime, I was learning from Bhasker’s engineer, <a href='https://pawelsek.com/' rel='noopener noreferrer' target='_blank'>Pawel Sek</a>, and a producer he had just signed, <a href='https://open.spotify.com/playlist/37i9dQZF1EFMKN3w2btH9A?si=74ce0b4ae41046d1' rel='noopener noreferrer' target='_blank'>Tyler Johnson</a>. Who's now known because he made “<a href='https://open.spotify.com/track/6UelLqGlWMcVH1E5c4H7lY?si=67b37db6ff664f4a' rel='noopener noreferrer' target='_blank'>Watermelon Sugar</a>” and “<a href='https://open.spotify.com/playlist/37i9dQZF1EFMKN3w2btH9A?si=74ce0b4ae41046d1' rel='noopener noreferrer' target='_blank'>As it Was</a>,” and all the Harry [Styles] songs. And they just started mentoring me. They caught on that I was decent at music and started teaching me how to set up the studio the way Bhasker liked, how to run a session and all of that stuff.</p>,
            <p>So I then would play piano every day to just see if maybe Bhasker would walk in and hear me playing. And one day he did and it kind of went from there. He was working on a personal project and gave me an assignment to make a beat for him. And I spent all night making it.</p>,
            <p>And then the next day he loved it and started just giving me more responsibility, like engineering for <a href='https://open.spotify.com/artist/1buzCmyYZE4kcdLRudsb8V?si=mbrZJ0r3RoCAWaxLG1sIoA' rel='noopener noreferrer' target='_blank'>Mikky Ekko</a> and <a href='https://open.spotify.com/artist/5WRElKaZsn1tGnrgmJVAeO?si=nPOnwZDjTbyLL3p4anVzUg' rel='noopener noreferrer' target='_blank'>Cam</a> and <a href='https://open.spotify.com/artist/3bhu7P5PfngueRHiB9hjcx?si=_Mlku00-Tge0V2Vfy64ltw' rel='noopener noreferrer' target='_blank'>Elle King</a>. And then eventually a year and a half in, he signed me.</p>,
            <p>Neil started managing me and I had built a little studio at my friend's garage and Neil was sending people to come and do sessions with me. And I took what I learned from seeing Jeff run them and tried to do my thing. And then it took off from there. I ended up getting Beck, which was crazy and then Young the Giant and started making albums. Then the <a href='https://open.spotify.com/album/1FZKIm3JVDCxTchXDo5jOV?si=K2AMVhGvT2SXDhSZIbm2Aw' rel='noopener noreferrer' target='_blank'>Harry [Styles] record</a> happened. And from there it’s history.</p>,
            <p><strong>SS:</strong> I love that the piano was such a key component in all of it. That you grew up playing and that's what helped catapult your career professionally. While working on music, are there typical turning points or “aha” moments that you use to gauge a song's readiness to be released or any indicators of potential success?</p>,
            <p><strong>AS:</strong> A great song and production doesn't get tired. Every time you put it on, it has to make you feel something, no matter how many times you've heard it, ‘cause a hit is like that. Everytime you hear it, no matter if you've heard it a million times. You're still like, ‘what the fuck? This song's amazing.’</p>,
            <p>The hardest thing to accomplish when making a record is retaining objectivity, because that's what you're constantly battling is your ego and your perspective on the music. As soon as you start creating something yourself, you completely lose all objective perspective and you start to think that bad things are good.</p>,
            <div className='i-images-holder'>
                <img src={ascarl746055} alt='' />
                <small>Alex Salibian at The Village Studios</small>
            </div>,
            <p>So it's really, really important to reset. You take a break, you listen to something else, you sleep on it. And then when you listen to it again, you're trying to pretend that you don't know what it is, you can feel it. And then you can just continue tweaking and then you do eventually get to a point where every time you put it on you're like, this is it. It's done.</p>,
            <p><strong>SS:</strong> At <a href='https://www.nvakcollective.com/' rel='noopener noreferrer' target='_blank'>Nvak Collective</a>, the record label you launched this past year, much of your effort is focused on finding and incubating talent from outside of the U.S., and integrating their music into the industry prevalent in the States. Recently, the first artist signed to your roster, <a href='https://www.instagram.com/rosalinnmusic' rel='noopener noreferrer' target='_blank'>Rosa Linn</a>, has experienced a global hit with her song, "<a href='https://open.spotify.com/track/76OGwb5RA9h4FxQPT33ekc?si=26f2622a44254d90' rel='noopener noreferrer' target='_blank'>SNAP.</a>" What has that experience been like?</p>,
            <p><strong>AS:</strong> It's been very exciting and very exhausting. There's so much that goes into supporting an artist through their breakout hit.</p>,
            <p>One hit wonders are a thing, it's the last thing you want. And the pressure to use the window of time when we do have the attention of the world to ensure that she has everything she needs to succeed for a lifetime has been the difficult thing to do. Making sure the record's doing well, but also planning the next records. Making sure that she's happy and healthy through the process and not overworked, but still doing a lot of hard work.</p>,
            <p>There's no time really to just celebrate and be like, ‘woo-who.’ In today's market, everything just moves like that. And before you know it, no one will be picking up the phone calling you. Tamar [Nvak Collective’s CEO] and I, we did have a moment where we were like, ‘whoa, this is great, pat ourselves on the back, we got a hit in 10 months of operating.’ Which is insane. But, then the stress and pressure creeps in. We cannot screw this up for Rosa Linn. She’s gotta win. That's it. The yin and yang with everything.</p>,
            <div className='i-images-holder'>
                <img src={asalexss} alt='' />
                <small>Alex Salibian with Nvak Collective co-founder, Tamar Kaprelian</small>
            </div>,
            <p><strong>SS:</strong> Congrats on recently joining the <a href='https://open.spotify.com/playlist/37i9dQZF1DX7iB3RCnBnN4?si=c4680470cd1848d4' rel='noopener noreferrer' target='_blank'>Billions Club</a> on Spotify as a songwriter and producer on "<a href='https://open.spotify.com/track/5Ohxk2dO5COHF1krpoPigN?si=f9e7f2dafc074b35' rel='noopener noreferrer' target='_blank'>Sign of the Times</a>" by Harry Styles. At the time of writing that, did you have an awareness that this song had the potential to go on and reach as many people as it did and become the hit that it is?</p>,
            <p><strong>AS:</strong> So that song was myself, Harry [Styles], Jeff Bhasker, Tyler Johnson, Ryan Nasci and Mitch Rowland, all in a small studio in Jamaica. And there was a moment where Harry started playing the chords on the piano and singing the melody. And everyone in the room was like, let's work on that. And it came together very quickly. Bhasker was the one who brought me, he was the boss of the session. With his experience, he was very quickly able to be like, ‘okay, team, let's do this, let's finish this’. Capturing the energy that is happening right now and directing everyone to do what they needed to do.</p>,
            <p>And then before we knew it, we had this whole thing. That was it. I think it was much longer actually. There’s an intro and a long outro and extra time and everything. And we knew that there was something very special about that. And then like most things, the difficult part through that is the editing process. And retaining all of that magic while trimming the fat. But it was one of those where you could sense it in the room that there's something really great happening here. Definitely didn't understand the impact it would have because we wrote another song the next day. And had written another song the day before. And we were just kind of in it, you had no clue what's gonna happen at that point. Harry [Styles] has done a very, very good job at just expanding and expanding, in a very authentic and impactful way where he takes such good care of his fans.</p>,
            <p>And I think a lot of it has to do with that too. Granted it's a great record and a great song, but he just delivers it so well.</p>,
            <div className='i-images-holder'>
                <img src={ascarlyshcarlyshr1e037} alt='' />
                <img src={ascarlyshcarlyshr1e032} alt='' />
            </div>,
            <p><strong>SS:</strong> Is there any advice you would give to yourself at that point in time knowing what you know now?</p>,
            <p><strong>AS:</strong> Oh my God. Be present. Throughout that process, the anxiety was so high for me. And I remember talking to Tyler about it too, because we felt the pressure. And we were like, ‘we need to deliver, this is a big opportunity,’ but we're also having a great time.</p>,
            <p>We're like, ‘can we have fun?’ So, I think that the thing would've been to be present and to really soak in the moment.</p>,
            <p><strong>SS:</strong> Over the last two years, you've been building Nvak Collective. How has your background as a producer affected your approach to building a label?</p>,
            <p><strong>AS:</strong> So, the whole purpose of the label for both Tamar and myself was to be creator first. So, every decision that we've made and how to set it up has been a balance of what would producers, songwriters and artists need in the situation? What systemic issues are they constantly facing? What did we feel when we were in those rooms? How do we fix that while creating a business model that's viable and actually will keep the doors open.</p>,
            <div className='i-images-holder'>
                <img src={as949390010024} alt='' />
                <small>Alex Salibian with Nvak Collective co-founder, Tamar Kaprelian</small>
            </div>,
            <p>It's really, really important to create something that's sustainable and can continue to bring in revenue. But at the same time is changing things for the better. So, without the experience that I've had as a producer and communicating with artists, I can see why it's so easy to get lost in the business of a record label. It's extremely difficult and it's creative. So there's a lot of subjective opinion in it and the stakes are high. You need records to work, but if neither of us had that place ingrained in our brains of actually feeling and knowing it [from the creator perspective], I could see it being very easy to get lost in the process of it.</p>,
            <p><strong>SS:</strong> And then on the flip of that, how has building a record label and being involved on the business side of the industry impacted your role as a producer and has it changed the way you work at all?</p>,
            <img src={ascarl746066} alt='' />,
            <p><strong>AS:</strong> Yeah, it's changed tremendously. One of the questions I always had as a producer was what happened to my baby once it left the room and was sent to an A&R, and I never knew, and being in that seat, [I understand now].</p>,
            <p>It's made it very clear that the song is the only thing that matters and a production that takes away from the song or distracts from it is not a good idea.</p>,
            <p>In terms of what I am producing now, it's simplified the process. And the first thing I start to look at is lyric, melody, and harmony, and just spend a lot of time getting those things right before adding even rhythm to it. The song should dictate the production instead of the production making up for not a great song.</p>,
            <p>When you're in a room with an artist and you have eight hours to make something, you're constantly making decisions about what to work on and what's your priority list? What's your pecking order? Do you get the guitar tone right? Do you figure out the chords, do you make the beat first? Do we work on the second verse as opposed to the first verse or the chorus? It’s always decision making and that’s the job of the producer to guide the session and it's just become a lot easier to decide.</p>,
            <p><strong>SS:</strong> What advice do you have for aspiring producers?</p>,
            <p><strong>AS:</strong> I would say to find your own unique sound and own it. And it's persistence, hard work, and getting rid of ego that are kind of the main three things.</p>,
            <p>Persistence, hard work, finding your own unique sound seem rather like, ‘okay, yeah, that makes sense.’ And in music, getting rid of ego is a common theme that comes up. But for myself, having the confidence to know that when I came up with an idea I love, and then the artist doesn't like it, I'm good enough to make a new idea based on what they want. The reaction is actually becoming more confident, not less.</p>,
            <img src={ascarlyshcarlyshr1e034} alt='' />,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Find <a href='https://www.instagram.com/alexsalibian/?hl=en' rel='noopener noreferrer' target='_blank'>Alex Salibian</a> on socials @alexsalibian. Follow along for updates on musical releases and <a href='https://www.nvakcollective.com/' rel='noopener noreferrer' target='_blank'>Nvak Collective</a>.
                </em>
                <br />
                <br />
                <em>Photos and words by <a href='https://www.instagram.com/carlyjeansharp/' rel='noopener noreferrer' target='_blank'>Carly Sharp</a>.</em>
                <br />
                <br />
                <em>Learn more about production and the music business from Song Start — recommended episodes:</em>
                <br />
                Video: <a href='https://noteable.spotify.com/songstart/demo-set-up-kito' target='_blank' rel='noopener noreferrer'>Kito on Setting up for a Demo</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/the-new-industry-alex-justice' target='_blank' rel='noopener noreferrer'>Alex Salibian and Justice Badain on New Ways of Working in the Music Industry</a>
            </p>,
        ],
    },
    {
        title: `Annika Rose on the making of her sophomore EP and navigating the music industry while growing up in it`,
        url: `/annika-rose-on-the-making-of-her-sophomore-ep`,
        image: anikaFeature,
        children: [
            <p><em>After a year in her own sort of lockdown, full of long days and nights in the studio, singer-songwriter Annika Rose is venturing into uncharted territory and exploring a new era of music— filled with fresh collaborators and innovative spaces, like Web3.</em></p>,
            <img src={aRSongStartHeader} alt='' />,
            <p><em>At just 20 years old, Annika Rose has already clocked nearly a decade in the music industry. But her upcoming music release cycle is quickly proving to be different for the LA native.</em></p>,
            <p><em>Since departing from the major label system in 2020, Annika Rose has self-released a handful of singles — the latest, “Hypocrite,” was released in May of 2021. Her anticipated subsequent EP has credits including Ali Tamposi (Dua Lipa, Miley Cyrus, Justin Bieber), Paul Phamous (Frank Ocean, Nick Jonas, Lennon Stella), and Alex Salibian (Harry Styles, Young the Giant) — collaborators that she cites as major reasons that this body of work and the process of creating it has been vastly different, and in her own words, “better” than preceding work.</em></p>,
            <p><em>On the brink of handing over the lead single from her sophomore EP to her awaiting audience, Song Start spoke with Annika Rose about the evolution of her creative process, advice for rising songwriters, and what she is most looking forward to as she gears up for new releases.</em></p>,
            <p><strong>Song Start:</strong> When did you get your start in music?</p>,
            <p><strong>Annika Rose:</strong> I sorta just fell into it. I don’t think my life could’ve gone in any other direction. I started a YouTube channel when I was like 7, singing covers in my bathtub (call me crazy but the acoustics are bomb), joined my first band when I was 10, and somehow ended up signing my first major record deal at 13. <br /><br />An A&R at Sony Music in the U.K. had found my band's cover of The Pretender by the Foo Fighters, and that’s how I was scouted to be in the girl band that signed to Syco [a record label overseen by Simon Cowell] when I was 13. From then on, I was thrown into the major label system and a million blind-date pop [songwriting] sessions. Some in which I learned a lot and built some beautiful relationships, others in which I contemplated leaving the Earth.</p>,
            <div className='i-images-holder'>
                <img src={carl987065copy} alt='' />
                <img src={carl987055} alt='' />
            </div>,
            <p><strong>SS:</strong> You are in the process of making new music, what can we expect to see from you this year?</p>,
            <p><strong>AR:</strong> So much! A whole new way of experiencing music honestly. I'm experimenting a lot in Web3 and trying to really highlight and spearhead new ways for music to be consumed and valued. There's gonna be a lot of virtual elements involved in the upcoming releases, and hopefully a wide range of new ways for people to really engage in a music experience. And then, of course, shows — a hell of a lot of them hopefully. I'm ready to hug some people.</p>,
            <img src={carl987106} alt='' />,
            <p><strong>SS:</strong> You’ve been making and releasing music for years — has there been anything different about the process of making this EP?</p>,
            <p><strong>AR:</strong> Oh god. Loaded question. The entire process was different. The people involved were different. I was different. It was meticulous, and slow, and immensely challenging at times. I had never gone through a full on A&R process while making a body of work, mostly because I’m quick to tell everyone to back the fuck off. Which is productive sometimes, but not always. So I had to give a little this time around. And let me tell you, it really forced me to practice patience and a willingness to be open.</p>,
            <div className='i-images-holder'>
                <img src={carl746051} alt='' />
                <img src={carlysh_carlyshR20187A} alt='' />
                <small>L: Annika Rose at the Village Studios, R: Annika Rose working with Alex Salibian and Paul Phamous</small>
            </div>,
            <p>I'm glad it happened the way it did though, I learned a lot and the songs came out the other side of it in a really surprising way. Better, I think too. I'm an extremely private and protective person, especially when it comes to my creative process. This body of work is a product of stepping outside personal boundaries and allowing room for change, experimentation, and trust.</p>,
            <p><strong>SS:</strong> Is there anything new you have learned about yourself as an artist during this process?</p>,
            <p><strong>AR:</strong> So much. Mostly that just because things were done a certain way at one point, doesn’t mean it has to be done that way every time. We are ever-evolving creatures and I think it’s only fair to allow room for things and ideas and processes to freely flow and change, even if letting go of the safe option feels wildly intimidating.</p>,
            <div className='i-images-holder'>
                <img src={carl746058} alt='' />
                <small>Annika Rose at the Village Studios with her producers and bandmates.</small>
            </div>,
            <p><strong>SS:</strong> When making music, there can be lots of people in the room with different opinions. How do you determine which voices to listen to and when to stand your ground? As an artist, what advice would you give to other artists on finding their voice in these full rooms?</p>,
            <p><strong>AR:</strong> First and foremost, make sure you’re not standing in a room full of people you don’t trust. At least to a certain extent. Without that, you’re bound to find yourself in a very emotionally precarious situation. I've been there. It sucks. And even with people you trust, it still sometimes sucks. But I know we all at least have the same goal and I take comfort in that.</p>,
            <img src={carl987100} alt='' />,
            <p>I'm at the point now where if somebody offers up an idea, most of the time I'll try it. Unless I just straight up know for a fact it doesn’t align with the song. ’Cause why not? What was there before isn’t going away. If it doesn’t work, it doesn’t work. Cool. It's important that there's a mutual respect between everyone in the room. Try things, value all input. But always, always hold your ground if it doesn’t feel right.</p>,
            <div className='i-images-holder'>
                <img src={carl746008} alt='' />
                <img src={carlysh_carlyshR202611A} alt='' />
            </div>,
            <p>I used to be scared of hurting feelings even if my delivery of saying “no” was gentle and respectful. That's who I am by nature. But now I just say no when I need to. Always with respect, but no. Because it’s my song. Simple. And the right people will respect you for it. And that’s my advice for everyone.</p>,
            <p><strong>SS:</strong> Great advice. What is your music making process like? Do you write alone, with others, a mixture of both? Walk us through how these songs started as thoughts and ended up on the EP.</p>,
            <p><strong>AR:</strong> It's always different! I like to start everything at home though. Me, my piano, and a dim light with a few moments of very heavy hitting emotional outbursts. I don't believe anyone can write my stories better than I can. But I do believe people can elevate them and nurture them to a stronger point. That's been my process for years now. <br /><br /> Some of the new songs were started at home and finished in the studio, and others were just me flooding into the room with my close collaborators Paul Phamous and Bailey Lindley raging about some crazy shit I needed to get off my chest. And so we would write those songs right then and there. But again, that wouldn’t happen without trust. Trust that my stories are sacred to the people helping me articulate them, and trust that my vulnerability was being taken seriously.</p>,
            <div className='i-images-holder'>
                <img src={carl746101} alt='' />
                <small>Annika Rose with collaborators Bailey Lindley and Paul Phamous.</small>
            </div>,
            <p>A song can be written in an hour, it’s the process following that can feel like it takes a million years. Some of the songs were written 2 years ago, and others just a few months back.</p>,
            <img src={carlysh_carlyshR204420A} alt='' />,
            <p><strong>SS:</strong> You’re only 20 but have already been in this industry for close to a decade — how do you avoid burnout? And how do you protect your artistry, creativity, and mental health?</p>,
            <p><strong>AR:</strong> I've gotten really good at saying no. I've acquired a group of people around me who know humans aren’t robots. I take a lot of walks. I meditate. I write a lot of shit down. I spend a lot of time in nature. I remember nothing is actually all that serious. We’re just little evolved animals. That thought helps.</p>,
            <img src={carl987049} alt='' />,
            <p><strong>SS:</strong> Sounds like you are able to keep yourself very grounded. What is your favorite part about being an artist?</p>,
            <p><strong>AR:</strong> I don't think it’s ever felt like a real job. I'm excited to wake up every day and make stuff. I just get to make stuff? That's weird. And cool. It’s allowed for so many different forms of connection with people all over the world. Even on a small scale. It's just so meaningful.</p>,
            <p><strong>SS:</strong> Who are your biggest music influences?</p>,
            <p><strong>AR:</strong> Alanis Morissette. Jagged Little Pill — are you kidding me??? That’s my get stranded on an island record. Paramore (duh), Fleetwood Mac, Radiohead, Tom Petty, the 1975… the list goes on.</p>,
            <div className='i-images-holder'>
                <img src={carl746080} alt='' />
                <small>Annika Rose at The Village Studios in Los Angeles — where classic songs by some of her favorite artists (Fleetwood Mac, Tom Petty) have also been recorded.</small>
            </div>,
            <p><strong>SS:</strong> Can you share anything more about the upcoming EP with us?</p>,
            <p><strong>AR:</strong> IT’S REALLY GOOD.</p>,
            <img src={polaroid4Cropped} alt='' />,
            <p><strong>SS:</strong> When can we expect new music from you?</p>,
            <p><strong>AR:</strong> Sooner than ya think!</p>,
            <p><strong>SS:</strong> What are you most looking forward to in the next year?</p>,
            <p><strong>AR:</strong> Touring!</p>,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Find <a href='https://www.annikarosemusic.com/' target='_blank' rel='noopener noreferrer'>Annika Rose</a> on all socials @annikarosemusic. Follow along for updates on her musical releases.
                </em>
                <br />
                <br />
                <em>Photos and words by Carly Sharp.</em>
                <br />
                <br />
                <em>Learn more about songwriting and the creative process from Song Start — recommended episodes:</em>
                <br />
                Video: <a href='https://noteable.spotify.com/songstart/inspiration-and-collaboration-tove-lo-charli-xcx' target='_blank' rel='noopener noreferrer'>Charli XCX and Tove Lo on Inspiration & Collaboration</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/avoiding-burnout-hayley-williams' target='_blank' rel='noopener noreferrer'>Hayley Williams on Sustainable Creativity and the Way Out of Burnout</a>
            </p>,
        ],
    },
    {
        title: `Jesse Jo Stark on finding your voice in music, listening to your intuition, and what a festival performance day is really like`,
        url: `/jesse-jo-stark-on-finding-your-voice-in-music`,
        image: jjsFeature,
        children: [
            <p><em>Taking the BMI stage, Jesse Jo Stark made her Lollapalooza debut at Chicago’s largest annual musical festival. With two shows in one day, the singer-songwriter performed live for the first time since the start of the pandemic.</em></p>,
            <p><em>Her most recent musical project, DOOMED, and the accompanying imagery, are a showcase of Stark’s unique artistry — both sonically and visually. Stark’s debut album has credits including Matt Schwartz (YUNGBLUD, Polo G), Michael Harris (HAIM, Lana Del Rey, Angel Olsen), and Jesse Rutherford (The Neighbourhood) and will be available everywhere in September.</em></p>,
            <p><em>Stark’s music is bold and transports the listener to a previous era. Her sound is unmistakably a reflection of her own identity — a duality of softness and edginess led by a rock-n-roll persona.</em></p>,
            <p><em>Her music is laced with an authenticity she attributes to her ability to trust her gut and her willingness to be “weird” while in the music-making process.</em></p>,
            <p><em>Stark joined Song Start to chat about her experience at the festival, what listeners can expect from her debut album, and what she is most hopeful for in the next year.</em></p>,
            <img src={polaroid3JJS} alt='' />,
            <p>
                <strong>Song Start:</strong> Congratulations on your Lollapalooza debut performances last week. How was your experience at the festival?
            </p>,
            <p>
                <strong>Jesse Jo Stark:</strong> It was really cool. I was kind of intimidated by the whole thing, considering it was my first time playing Lolla[palooza] and first show in a minute after COVID. But I was very excited to share the stage with my band again and play with people and seeing people sing my lyrics back to me was really cool and it felt nice and impactful to be with people again and play music live — it was a really exciting day.
            </p>,
            <p>
                <strong>SS:</strong> Can you walk us through what your performance day entailed at the festival?
            </p>,
            <p>
                <strong>JJS:</strong> I woke up, got coffee, went downstairs, got in the van, drove to the festival, did some press, played with my monster finger puppet because I had anxiety, talked to my mom, walked around with some friends, had a beer, and had some chicken tenders. Did some vocal warm ups, walked to the stage, played, said “hi” to everybody that came to the show and then took a little buggy back to the tent. And then I got ready to play my second show with Hinds, and changed my [out]fit, that was about it.
            </p>,
            <img src={aftershow3jjs} alt='' />,
            <p><strong>SS:</strong> With two performances in one day, how do you keep yourself focused and pumped up? Do you have any pre-show rituals?</p>,
            <p><strong>JJS:</strong> Yeah, we always listen to a song or two to get us in the mood. We say a little prayer. I try to keep it pretty chill. I get pumped, I would say, third song in. I like to arrive a little more dead. I keep it pretty chill backstage.</p>,
            <p><strong>SS:</strong> You are in the process of releasing your debut album. How has this cycle of creating and releasing music been different from your debut EP?</p>,
            <img src={aftershow4JJSlarge} alt='' />,
            <p><strong>JJS:</strong> I think building an album is a lot harder. There’s a lot of things that go with it I didn't really realize. After the studio, you kind of think you’re done and you’re not at all. Building this world around one cohesive idea and making sure that all my inspirations really lend to the story that I’m trying to tell. It’s been fun, you know, building a visual world around it. <br />That’s my favorite part. Everytime I write, I always think of the video and the song. So that's been really cool. And now, I think, there's just so many different levels. Now I have to actually put it out and tour. They just keep adding up on top of each other.</p>,
            <p><strong>SS:</strong> You have been able to find a sound and create music that feels very authentic to who you are — what advice do you have for other artists and songwriters to be able to do the same?</p>,
            <p><strong>JJS:</strong> Lead with your gut. I don't really know what else we have to offer except ourselves. I don't really think there's a method in my book. I don't know, selfishly, if I make things hoping someone will like them. I do after but in the process I just make what I want to make and things I know that I back. I don't want to play anything I don't like. <br /><br />So I just try to bring authenticity to everything I do and I guess that's what I would say to others. And honestly, really sharing that vulnerability and I think that took me a longer time. And not being afraid to just be really weird. Because I think that's what music is. It's kind of a guessing game and just being weird.</p>,
            <div className='i-images-holder'>
                <img src={aftershow6JJS} alt='' />
                <img src={aftershow2JJS} alt='' />
            </div>,
            <p><strong>SS:</strong> That's amazing advice. What can fans expect from you on your debut album, DOOMED? Anything you can share with us?</p>,
            <p><strong>JJS:</strong> I think DOOMED is a bunch of different moods in a day. So if you're a moody bitch, I would expect some kind of liberation there. You can just put it on your car and go through the motions. I would say to expect the opposite of what it sounds like.</p>,
            <p><strong>SS:</strong> Who are your biggest musical influences?</p>,
            <p><strong>JJS:</strong> The Cramps.</p>,
            <img src={aftershow5JJS} alt='' />,
            <p><strong>SS:</strong> What's your wish-you-wrote-it song?</p>,
            <p><strong>JJS:</strong> “<a href='https://open.spotify.com/track/4RVwu0g32PAqgUiJoXsdF8?si=221980a184244f25' target='_blank' rel='noopener noreferrer'>Happier Than Ever</a>” [Billie Eilish], “<a href='https://open.spotify.com/track/1A6P8IxzNyBRsQualaNXNY?si=5606ef17381f4430' target='_blank' rel='noopener noreferrer'>Pancho and Lefty</a>” [Willie Nelson, Merle Haggard], “<a href='https://open.spotify.com/track/0Z57YWES04xGh3AImDz6Qr?si=619f9fc833ba48c6' target='_blank' rel='noopener noreferrer'>Real Love Baby</a>” [Father John Misty].</p>,
            <p><strong>SS:</strong> What are you most looking forward to in the next year?</p>,
            <p><strong>JJS:</strong> Playing shows, new experiences. The world seems really dark so I’m trying to have a good time but in an environmentally friendly way.</p>,
            <p className='fs-smaller'>
                <br />
                <br />
                <em>
                    Connect with <a href='https://jessejostark.com/' target='_blank' rel='noopener noreferrer'>Jesse Jo Stark</a> on socials @jessejostark. Her debut album, <a href='https://jessejostark.salinks.co/doomed' target='_blank' rel='noopener noreferrer'>DOOMED</a>, will be available on all streaming platforms on September 21, 2022.
                </em>
                <br />
                <br />
                <em>Photos and words by Carly Sharp.</em>
                <br />
                <br />
                <em>Learn more about songwriting, musical identity, and stage persona from Song Start — recommended episodes:</em>
                <br />
                Video: <a href='https://noteable.spotify.com/songstart/artistry-stage-persona-hayley-ingrid' target='_blank' rel='noopener noreferrer'>Hayley Kiyoko and Ingrid Andress on Artistry and Stage Persona</a>
                <br />
                Podcast: <a href='https://noteable.spotify.com/songstart/artistry-identiy-faouzia-abir' target='_blank' rel='noopener noreferrer'>Faouzia and Abir on Artistry and Identity</a>
            </p>
        ],
    },
];
